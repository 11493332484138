import $ from "jquery";

export default function stickyTableHeader({ selector = "", adjustBottom }) {
  if (!selector) {
    return;
  }

  const $tableResponsive = $(`${selector} .table-responsive`);
  const $table = $(selector);
  
  const $footer = $table.find(".tr-footer-shippping-plan");
  const $footerClone = $footer.clone();
  $footerClone.addClass("tfoot-clone");

  const $thead = $table.find("thead");
  const $theadClone = $thead.clone();
  $theadClone.addClass("thead-clone");

  if ($footer.length > 0) resetStickyFooter({ $table, $footer });

  $(".main-panel").off("scroll");
  $(window).off("resize");
  $tableResponsive.off("scroll");

  $(".main-panel").on("scroll", () => {
    resetStickyHeader({ $table, $theadClone, $thead, selector });
    if ($footer.length > 0) stickyFooter({ $table, $footer, $footerClone, selector });
  });
  $(window).on("resize", () => {
    resetStickyHeader({ $table, $theadClone, $thead, selector });
    if ($footer.length > 0) stickyFooter({ $table, $footer, $footerClone, selector });
  });
  $tableResponsive.on("scroll", () => {
    handleTableScroll({ $tableResponsive, $table, $thead });
  });
}

function handleTableScroll({ $tableResponsive, $table, $thead }) {
  const offsetLeft = $tableResponsive.scrollLeft();
  const { top } = $table.offset();
  if (top <= 0) {
    $thead.find("tr").css({
      transform: `translateX(-${offsetLeft}px)`,
    });
  }
}

function resetStickyHeader({ $table, $theadClone, $thead, selector }) {
  if (!$table) {
    return;
  }

  const $tableResponsive = $(`${selector} .table-responsive`);
  const { top } = $table.offset();
  const offsetLeft = $tableResponsive.scrollLeft();

  if (top <= 0) {
    if (!$table.has(".thead-clone")[0]) {
      $table.find("table").prepend($theadClone);
    }

    $thead.css({
      position: "fixed",
      top: 0,
      width: $tableResponsive.outerWidth(),
      overflow: "hidden",
      background: "#fff",
      zIndex: 1,
    });
    $thead.find("tr").css({
      display: "flex",
      alignItems: "flex-end",
      transform: `translateX(-${offsetLeft}px)`,
    });
    $thead.find("th").css({
      flexShrink: 0,
    });

    $thead.find("th").each((i, el) => {
      $(el).css({ width: $($theadClone.find("th")[i]).outerWidth() });
    });
  } else {
    $table.find(".thead-clone").remove();
    $thead.removeAttr("style");
    $thead.find("tr").removeAttr("style");
    $thead.find("th").removeAttr("style");
  }
}

function stickyFooter({ $table, $footer, $footerClone, selector }) {
  if (!$table || !$footer || !$footerClone) {
    return;
  }

  if (!$table.has(".tfoot-clone")[0]) {
    $table.find("tbody").append($footerClone);
  }

  const $tableResponsive = $(`${selector} .table-responsive`);
  const $prev = $footer.prev();
  const overViewport = $prev.offset().top + $prev.height();

  let isVisible = overViewport > $(window).innerHeight();
  if (isVisible) {
    $footer.css({
      position: "fixed",
      bottom: 0,
      zIndex: 1,
      width: $tableResponsive.outerWidth(),
    });
    $footer.find("td").each((i, el) => {
      $(el).css({ width: $($footerClone.find("td")[i]).outerWidth() });
    });
  } else {
    $table.find(".tfoot-clone").remove();
    $footer.css({
      position: "relative",
    });
    $footer.find("td").removeAttr("style");
  }
}

function resetStickyFooter({ $table, $footer }) {
  if (!$table || !$footer) {
    return;
  }

  $table.find(".tfoot-clone").remove();
  $footer.css({
    position: "relative",
  });
  $footer.find("td").removeAttr("style");
}

export function resetHeaderWidth({ selector }) {
  const $table = $(selector);
  const $thead = $table.find("thead");
  const { top } = $table.offset();

  if (top <= 0) {
    $thead.find("th").each((i, el) => {
      $(el).css({
        width: $($table.find(".clone").find("th")[i]).outerWidth(),
      });
    });
  }
}
