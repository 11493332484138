<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="login">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <img src="/img/i-logo.png" style="height: 50px; width: 50px" />
              </div>
              <h4 class="title">Login</h4>
            </md-card-header>

            <md-card-content>
              <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed, errors }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t("login.emailAddress") }}</label>
                  <md-input v-model="email" type="email"> </md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
              <ValidationProvider name="password" rules="required|min:6" v-slot="{ passed, failed, errors }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t("login.password") }}</label>
                  <md-input v-model="password" type="password"> </md-input>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </md-field>
              </ValidationProvider>
            </md-card-content>

            <md-card-actions>
              <md-button type="submit" class="md-success" :disabled="invalid">{{ $t("login.login") }}</md-button>
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import tokenService from "../../../services/token.service";
export default {
  metaInfo: {
    meta: [{ charset: "utf-8" }],
  },
  components: {},
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    ...mapActions("auth", ["loginUser"]),
    async login() {
      try {
        await this.loginUser({ email: this.email, password: this.password });
        if (this.returnUrl && this.returnUrl.startsWith("/")) {
          this.$router.push(this.returnUrl);
        } else {
          this.$router.push("/app/dashboard");
        }
      } catch (error) {
        this.$notify({
          timeout: 2500,
          message: this.$t("login.serverError"),
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return;
      }
    },
  },
  created() {
    try {
      var user = tokenService.getUser();
      if (user && user.userId) {
        this.$router.push("/app/dashboard");
      }
    } catch (error) {}
  },
};
</script>

<style></style>
