export const formatDate = (date) => {
  return date && date.toString();
};

export const normalizeStatusName = (statusName) => {
  return statusName.trim().toLowerCase().replaceAll(" ", "_");
};

export const FormatsMixin = {
  methods: {
    formatDate,
    normalizeStatusName,
  },
};
