import axiosFactory from "./axios.factory";
import BaseService from "./base.service";

/**
 * News Service
 * @class PostService
 * @extends BaseService
 */
class PostService extends BaseService {
  // overwrite or add extra methods to the service
  async getAll() {
    const result = await axiosFactory().get(`/${this.entity}`);
    return result.data.data.map(item=> ({id: item.id, title: item.headline, content: item.text, date: item.date}));
  }
}

export default new PostService('common/news');
