import ShippingPlanService from "../services/shipping-plan.service";

const initialState = {
  shippingPlans: [],
  histories: [],
  historyAvatars: {},
  shippingPlan: {},
  totalItems: 0,
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    getShippingPlans({ commit }, opts) {
      return ShippingPlanService.getShippingPlans(opts).then(
        (result) => {
          commit("getShippingPlansSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getShippingPlansFailure");
          return Promise.reject(error);
        }
      );
    },
    getShippingPlan({ commit }, opts) {
      return ShippingPlanService.getShippingPlan(opts).then(
        (result) => {
          commit("getShippingPlanSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getShippingPlanFailure");
          return Promise.reject(error);
        }
      );
    },
    getHistoricalShippingPlan({ commit }, opts) {
      return ShippingPlanService.getHistoricalShippingPlan(opts).then(
        (result) => {
          commit("getHistoricalShippingPlanSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getHistoricalShippingPlanFailure");
          return Promise.reject(error);
        }
      );
    },
    createShippingPlan(_, opts) {
      return ShippingPlanService.createShippingPlan(opts).then(
        (result) => {
          // commit("createShippingPlansSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    block(_, opts) {
      return ShippingPlanService.block(opts).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    release(_, opts) {
      return ShippingPlanService.release(opts).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getShippingPlansSuccess(state, result) {
      state.shippingPlans = result.items;
      state.totalItems = result.totalItems;
    },
    getShippingPlansFailure(state) {
      state.shippingPlans = null;
    },
    getHistoricalShippingPlanSuccess(state, result) {
      state.histories = result.historicalShippingPlan;
      state.historyAvatars = result.avatars;
    },
    getHistoricalShippingPlanFailure(state) {
      state.histories = null;
    },
    getShippingPlanSuccess(state, result) {
      state.shippingPlan = result;
    },
    getShippingPlanFailure(state) {
      state.shippingPlan = null;
    },
    createShippingPlansSuccess(state, result) {
      state.shippingPlans = [result, ...state.shippingPlans];
    },
  },
};
