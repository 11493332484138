import UserService from "../services/user.service";

const initialState = {
  users: [],
  roles: [],
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    createUser(_, opts) {
      return UserService.createUser(opts).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editUser(_, opts) {
      return UserService.editUser(opts).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getUsers({ commit }) {
      return UserService.getUsers().then(
        (result) => {
          commit("getUsersSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getUsersFailure");
          return Promise.reject(error);
        }
      );
    },
    getRoles({ commit }) {
      return UserService.getRoles().then(
        (result) => {
          commit("getRolesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getRolesFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getUsersSuccess(state, result) {
      state.users = result;
    },
    getUsersFailure(state) {
      state.users = null;
    },
    getRolesSuccess(state, result) {
      state.roles = result;
    },
    getRolesFailure(state) {
      state.roles = null;
    },
  },
};
