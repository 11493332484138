import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth";
import users from "./users";
import shippingPlans from "./shipping-plan";
import shippingData from "./shipping-data";
import warehouses from "./warehouse";
import posts from "./posts";  

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    users,
    shippingPlans,
    shippingData,
    warehouses,
    posts
  },
});
