import ShippingDataService from "../services/shipping-data.service";

const initialState = {
  recordStatuses: [],
  recordTags: [],
  supplierNames: [],
  containerSizes: [],
  containerArrivalPlaces: [],
  carrierNames: [],
  paymentStatuses: [],
  shipmentPaidStatuses: [],
  customsPaidStatuses: [],
  chinaStatuses: [],
  linkedProducts: [],
  chinaUsers: [],
  products: [],
  accountingStatuses: [],
  shippingStatus: [],
  carriers: [],
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    getRecordStatuses({ commit }) {
      return ShippingDataService.getRecordStatuses().then(
        (result) => {
          commit("getRecordStatusesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getRecordStatusesFailure");
          return Promise.reject(error);
        }
      );
    },
    getRecordTags({ commit }) {
      return ShippingDataService.getRecordTags().then(
        (result) => {
          commit("getRecordTagsSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getRecordTagsFailure");
          return Promise.reject(error);
        }
      );
    },
    getSupplierNames({ commit }) {
      return ShippingDataService.getSupplierNames().then(
        (result) => {
          commit("getSupplierNamesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getSupplierNamesFailure");
          return Promise.reject(error);
        }
      );
    },
    getContainerSizes({ commit }) {
      return ShippingDataService.getContainerSizes().then(
        (result) => {
          commit("getContainerSizesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getContainerSizesFailure");
          return Promise.reject(error);
        }
      );
    },
    getContainerArrivalPlaces({ commit }) {
      return ShippingDataService.getContainerArrivalPlaces().then(
        (result) => {
          commit("getContainerArrivalPlacesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getContainerArrivalPlacesFailure");
          return Promise.reject(error);
        }
      );
    },
    getCarrierNames({ commit }) {
      return ShippingDataService.getCarrierNames().then(
        (result) => {
          commit("getCarrierNamesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getCarrierNamesFailure");
          return Promise.reject(error);
        }
      );
    },
    getPaymentStatuses({ commit }) {
      return ShippingDataService.getPaymentStatuses().then(
        (result) => {
          commit("getPaymentStatusesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getPaymentStatusesFailure");
          return Promise.reject(error);
        }
      );
    },
    getShipmentPaidStatuses({ commit }) {
      return ShippingDataService.getShipmentPaidStatuses().then(
        (result) => {
          commit("getShipmentPaidStatusesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getShipmentPaidStatusesFailure");
          return Promise.reject(error);
        }
      );
    },
    getCustomsPaidStatuses({ commit }) {
      return ShippingDataService.getCustomsPaidStatuses().then(
        (result) => {
          commit("getCustomsPaidStatusesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getCustomsPaidStatusesFailure");
          return Promise.reject(error);
        }
      );
    },
    getChinaStatuses({ commit }) {
      return ShippingDataService.getChinaStatuses().then(
        (result) => {
          commit("getChinaStatusesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getChinaStatusesFailure");
          return Promise.reject(error);
        }
      );
    },
    getLinkedProducts({ commit }) {
      return ShippingDataService.getLinkedProducts().then(
        (result) => {
          commit("getLinkedProductsSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getLinkedProductsFailure");
          return Promise.reject(error);
        }
      );
    },
    getChinaUsers({ commit }) {
      return ShippingDataService.getChinaUsers().then(
        (result) => {
          commit("getChinaUsersSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getChinaUsersFailure");
          return Promise.reject(error);
        }
      );
    },
    getAccountingStatuses({ commit }) {
      return ShippingDataService.getAccountingStatuses().then(
        (result) => {
          commit("getAccountingStatusesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getAccountingStatusesFailure");
          return Promise.reject(error);
        }
      );
    },
    createSupplier(_, opts) {
      return ShippingDataService.createSupplier(opts).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editSupplier(_, opts) {
      return ShippingDataService.editSupplier(opts).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createProduct(_, opts) {
      return ShippingDataService.createProduct(opts).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    editProduct(_, opts) {
      return ShippingDataService.editProduct(opts).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getProducts({ commit }, opts) {
      return ShippingDataService.getProducts(opts).then(
        (result) => {
          commit("getProductsSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getProductsFailure");
          return Promise.reject(error);
        }
      );
    },
    getShippingStatuses({ commit }) {
      return ShippingDataService.getShippingStatuses().then(
        (result) => {
          commit("getShippingStatusesSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getShippingStatusesFailure");
          return Promise.reject(error);
        }
      );
    },
    getCarriers({ commit }) {
      return ShippingDataService.getCarriers().then(
        (result) => {
          commit("getCarriersSuccess", result);
          return Promise.resolve(result);
        },
        (error) => {
          commit("getShippingStatusesFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getRecordStatusesSuccess(state, result) {
      state.recordStatuses = result;
    },
    getRecordStatusesFailure(state) {
      state.recordStatuses = null;
    },
    getRecordTagsSuccess(state, result) {
      state.recordTags = result;
    },
    getRecordTagsFailure(state) {
      state.recordTags = null;
    },
    getSupplierNamesSuccess(state, result) {
      state.supplierNames = result;
    },
    getSupplierNamesFailure(state) {
      state.supplierNames = null;
    },
    getContainerSizesSuccess(state, result) {
      state.containerSizes = result;
    },
    getContainerSizesFailure(state) {
      state.containerSizes = null;
    },
    getContainerArrivalPlacesSuccess(state, result) {
      state.containerArrivalPlaces = result;
    },
    getContainerArrivalPlacesFailure(state) {
      state.containerArrivalPlaces = null;
    },
    getCarrierNamesSuccess(state, result) {
      state.carrierNames = result;
    },
    getCarrierNamesFailure(state) {
      state.carrierNames = null;
    },
    getPaymentStatusesSuccess(state, result) {
      state.paymentStatuses = result;
    },
    getPaymentStatusesFailure(state) {
      state.paymentStatuses = null;
    },
    getShipmentPaidStatusesSuccess(state, result) {
      state.shipmentPaidStatuses = result;
    },
    getShipmentPaidStatusesFailure(state) {
      state.shipmentPaidStatuses = null;
    },
    getCustomsPaidStatusesSuccess(state, result) {
      state.customsPaidStatuses = result;
    },
    getCustomsPaidStatusesFailure(state) {
      state.customsPaidStatuses = null;
    },
    getChinaStatusesSuccess(state, result) {
      state.chinaStatuses = result;
    },
    getChinaStatusesFailure(state) {
      state.chinaStatuses = null;
    },
    getLinkedProductsSuccess(state, result) {
      state.linkedProducts = result;
    },
    getLinkedProductsFailure(state) {
      state.linkedProducts = null;
    },
    getChinaUsersSuccess(state, result) {
      state.chinaUsers = result;
    },
    getChinaUsersFailure(state) {
      state.chinaUsers = null;
    },
    getProductsSuccess(state, result) {
      state.products = result;
    },
    getProductsFailure(state) {
      state.products = null;
    },
    getAccountingStatusesSuccess(state, result) {
      state.accountingStatuses = result;
    },
    getAccountingStatusesFailure(state) {
      state.accountingStatuses = null;
    },
    getShippingStatusesSuccess(state, result) {
      state.shippingStatuses = result;
    },
    getShippingStatusesFailure(state) {
      state.shippingStatuses = null;
    },
    getCarriersSuccess(state, result) {
      state.carriers = result;
    },
    getCarriersFailure(state) {
      state.carriers = null;
    },
  },
};
