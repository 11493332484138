<template>
  <Loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true" :color="'#21AE8C'"></Loading>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "CSLoading",
  components: { Loading },
  props: {
    isLoading: { type: Boolean, default: true },
  },
};
</script>
