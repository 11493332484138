<template>
  <div>
    <md-chip
      v-for="tag in tags"
      :key="tag.id"
      :style="`background-color: white !important; border: 2px solid ${
        tag.color || 'darkgray'
      }; line-height: normal; color: black !important`"
      >{{ tag.name }}</md-chip
    >
  </div>
</template>
<script>
export default {
  name: "RecordTag",
  props: {
    recordTag: {
      type: String,
    },
    recordTags: {
      type: Array,
    },
  },
  computed: {
    tags() {
      if (!this.recordTag) return [];

      var parts = this.recordTag && this.recordTag.split(",");
      if (!this.recordTags) {
        return [];
      }

      var res = this.recordTags.filter((t) => parts.includes(t.name)) || [];
      return res;
    },
  },
};
</script>
<style></style>
