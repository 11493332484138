const LOCAL_STORAGE_TOKEN = "token";
const LOCAL_STORAGE_USER = "user";

export const LANGUAGE_SELECTED = "language_selected";

class TokenService {
  setToken(user) {
    localStorage.setItem(LOCAL_STORAGE_TOKEN, user.token);
    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
  }

  clearToken() {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_USER);
  }

  getToken() {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN);
  }

  getUser() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));
  }

  get(key) {
    return localStorage.getItem(key);
  }

  set(key, value) {
    return localStorage.setItem(key, value);
  }
}

export default new TokenService();
