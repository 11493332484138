<template>
  <div class="md-layout">
    <CSLoading :is-loading="isLoading"></CSLoading>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <span class="material-symbols-outlined">shelves</span>
          </div>
          <h4 class="title">{{ $t("wms.warehouses") }}</h4>
          <md-button
            v-if="hasPermission('Write', 'Warehouses', '')"
            class="md-icon-button md-success add-button"
            @click="showAddModal"
          >
            <md-icon>add</md-icon>
          </md-button>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-25">
              <md-field md-clearable>
                <label>{{ $t("wms.keywords") }}</label>
                <md-input v-model="filter.keywords" @input="debounceApplyFilters"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-25">
              <div class="wrap-v-select custom-wrap-v-select" style="text-align: left; margin-bottom: 0">
                <label>{{ $t("wms.location") }}</label>
                <v-select
                  :options="allLocationOptions"
                  :placeholder="$t('wms.location')"
                  @input="handleSelectLocation"
                  class="custom-select"
                >
                </v-select>
              </div>
            </div>
            <div class="md-layout-item md-size-25">
              <md-field>
                <label>{{ $t("wms.warehouse") }}</label>
                <md-select
                  v-model="filter.warehouseId"
                  name="warehouseId"
                  id="warehouseId"
                  :disabled="isWarehouseAssigned"
                  @md-selected="debounceApplyFilters"
                >
                  <md-option :key="i.id" v-for="i in warehouseOptions" :value="i.id">{{ i.name }}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <v-server-table
            :columns="tableColumns"
            :options="tableOptions"
            ref="table"
            class="table-warehouses"
            id="table-warehouses"
          >
            <template #actions="{ row }">
              <span v-if="hasPermission('Read', 'Inventories', '')" @click="showHistoryModal(row.sku)"
                ><md-icon>history</md-icon></span
              >
            </template>
            <template #warehouse="{ row }">
              <div v-if="hasPermission('LimitRead', 'Warehouses', 'StorageLocation')" class="cell-warehouse">
                {{ row.warehouseName }}
              </div>
            </template>
            <template #location="{ row }">
              <div v-if="hasPermission('LimitRead', 'Warehouses', 'StorageLocation')" class="cell-location">
                {{ row.warehouseCode }}-{{ row.storageLocation }}
              </div>
            </template>
            <template #sku="{ row }">
              <div v-if="hasPermission('LimitRead', 'Warehouses', 'Sku')" class="cell-sku">
                {{ row.sku }}
              </div>
            </template>
            <template #name="{ row }">
              <div v-if="hasPermission('LimitRead', 'Warehouses', 'Sku')" class="cell-name">
                {{ row.name }}
              </div>
            </template>
            <template #inventory="{ row }">
              <div v-if="hasPermission('LimitRead', 'Warehouses', 'Inventory')" class="cell-inventory">
                {{ row.inventory }}
              </div>
            </template>
            <template #reserved="{ row }">
              <div v-if="hasPermission('LimitRead', 'Warehouses', 'Reserved')" class="cell-inventory">
                {{ row.reserved }}
              </div>
            </template>
            <template #date="{ row }">
              <div v-if="hasPermission('LimitRead', 'Warehouses', 'Date')" class="cell-date">
                {{ row.date | formatDateTime }}
              </div>
            </template>
            <template #purchasePrice="{ row }">
              <div v-if="hasPermission('LimitRead', 'Warehouses', 'PurchasePrice')" class="cell-purchase-price">
                {{ row.purchasePrice }} USD
              </div>
            </template>
            <template #additionalCosts="{ row }">
              <div v-if="hasPermission('LimitRead', 'Warehouses', 'AdditionalCosts')" class="cell-additional-costs">
                {{ row.additionalCosts }} EUR
              </div>
            </template>
            <template #exchangeRate="{ row }">
              <div v-if="hasPermission('LimitRead', 'Warehouses', 'ExchangeRate')" class="cell-exchangeRate">
                {{ row.exchangeRate }}
              </div>
            </template>
            <template #valueOfGoods="{ row }">
              <div
                v-if="
                  hasPermission('LimitRead', 'Warehouses', 'PurchasePrice') &&
                  hasPermission('LimitRead', 'Warehouses', 'AdditionalCosts')
                "
                class="cell-value-of-goods"
              >
                {{ calcValueOfGoods(row) }} EUR
              </div>
            </template>
          </v-server-table>
        </md-card-content>
      </md-card>
    </div>
    <modal v-if="editModal" @close="hideEditModal" class-name="warehouse-modal-container">
      <template slot="header">
        <h4 class="modal-title">
          {{ form.id > 0 ? $t("wms.editWarehouse") : $t("wms.addWarehouse") }}
        </h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideEditModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <ValidationObserver v-slot="{}" ref="editForm">
          <md-field>
            <label>{{ $t("wms.sku") }}</label>
            <md-input v-model="form.sku" type="text" disabled> </md-input>
          </md-field>
          <md-field>
            <label>{{ $t("wms.warehouses") }}</label>
            <md-select v-model="form.warehouseId" name="warehouseId" id="warehouseId" disabled>
              <md-option :key="i.id" v-for="i in warehouseOptions" :value="i.id">{{ i.name }}</md-option>
            </md-select>
          </md-field>
          <ValidationProvider name="storageLocationId" rules="required" v-slot="{ errors }">
            <md-field>
              <label>{{ $t("wms.location") }}</label>
              <md-select v-model="form.storageLocationId" name="storageLocationId" id="storageLocationId" disabled>
                <md-option :key="i.id" v-for="i in locationOptions" :value="i.id">{{ i.name }}</md-option>
              </md-select>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <md-field>
            <label>{{ $t("wms.inventory") }}</label>
            <md-input v-model="form.inventory" type="number"> </md-input>
          </md-field>
          <md-field>
            <label>{{ $t("wms.reserved") }}</label>
            <md-input v-model="form.reserved" type="number"> </md-input>
          </md-field>
          <md-field>
            <label>{{ $t("wms.purchasePrice") }}</label>
            <md-input v-model="form.purchasePrice" type="number"> </md-input>
          </md-field>
          <md-field>
            <label>{{ $t("wms.additionalCosts") }}</label>
            <md-input v-model="form.additionalCosts" type="number"> </md-input>
          </md-field>
          <md-field>
            <label>{{ $t("wms.exchangeRate") }}</label>
            <md-input v-model="form.exchangeRate" type="number"> </md-input>
          </md-field>
          <ValidationProvider name="form.historyTypeId" rules="required" v-slot="{ errors }">
            <md-field>
              <label>{{ $t("wms.historyType") }}</label>
              <md-select v-model="form.historyTypeId" name="form.historyTypeId" id="form.historyTypeId">
                <md-option :key="i.id" v-for="i in historyTypeOptions" :value="i.id">{{ i.name }}</md-option>
              </md-select>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
        </ValidationObserver>
      </template>
      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideEditModal">{{ $t("wms.close") }}</md-button>
        <md-button class="md-success" @click="editWarehouse">{{ $t("wms.save") }}</md-button>
      </template>
    </modal>

    <modal v-if="addModal" @close="hideAddModal" class-name="add-warehouse-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("wms.addWarehouse") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideAddModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <tabs
          :tab-name="[$t('orders.incomingGoods'), $t('orders.movementOfGoods'), $t('orders.manualAdjustment')]"
          color-button="success"
          content-flex-direction-column
        >
          <template slot="tab-pane-1">
            <ValidationObserver v-slot="{}" ref="incomingGoodsForm">
              <ValidationProvider name="form.storageLocation" rules="required" v-slot="{ errors }">
                <div class="wrap-v-select" style="text-align: left; margin-bottom: 15px">
                  <v-select
                    v-model="form.storageLocation"
                    :options="allLocationOptions"
                    :placeholder="$t('wms.location')"
                  >
                  </v-select>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </div>
              </ValidationProvider>
              <ValidationProvider name="form.sku" rules="required" v-slot="{ errors }">
                <div class="wrap-v-select" style="text-align: left; margin-bottom: 15px">
                  <v-select v-model="form.sku" :options="skuOptions" :placeholder="$t('wms.sku')"> </v-select>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </div>
              </ValidationProvider>
              <md-field>
                <label>{{ $t("wms.inventory") }}</label>
                <md-input v-model="form.inventory" type="number"> </md-input>
              </md-field>
              <md-field>
                <label>{{ $t("wms.purchasePrice") }}</label>
                <md-input v-model="form.purchasePrice" type="number"> </md-input>
              </md-field>
              <md-field>
                <label>{{ $t("wms.additionalCosts") }}</label>
                <md-input v-model="form.additionalCosts" type="number"> </md-input>
              </md-field>
              <md-field>
                <label>{{ $t("wms.exchangeRate") }}</label>
                <md-input v-model="form.exchangeRate" type="number"> </md-input>
              </md-field>
              <md-field>
                <label>{{ $t("wms.reason") }}</label>
                <md-input v-model="form.reason"> </md-input>
              </md-field>
            </ValidationObserver>
            <div style="margin-top: 15px">
              <md-button class="md-secondary mr-1" @click="hideAddModal">{{ $t("wms.close") }}</md-button>
              <md-button class="md-success" @click="addIncomingGoods">{{ $t("wms.save") }}</md-button>
            </div>
          </template>
          <template slot="tab-pane-2">
            <ValidationObserver v-slot="{}" ref="movementOfGoodsForm">
              <div class="md-layout" style="align-items: baseline">
                <div class="md-layout-item md-size-50">
                  <ValidationProvider name="form.warehouseInventory" rules="required" v-slot="{ errors }">
                    <div class="wrap-v-select" style="text-align: left">
                      <v-select
                        :filterable="false"
                        :options="options"
                        v-model="form.warehouseInventory"
                        @search="onSearchInvetories"
                        :placeholder="$t('wms.inventory')"
                      >
                        <template slot="no-options"> no record... </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                      <div class="md-error" v-if="errors[0]">
                        {{ $t(errors[0]) }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t("wms.quantity") }}</label>
                    <md-input v-model="form.quantity" type="number"> </md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout" style="margin-top: 15px">
                <div class="md-layout-item md-size-50">
                  <ValidationProvider name="form.toStorageLocation" rules="required" v-slot="{ errors }">
                    <div class="wrap-v-select" style="text-align: left; margin-bottom: 15px">
                      <v-select
                        v-model="form.toStorageLocation"
                        :options="allLocationOptions"
                        :placeholder="$t('wms.location')"
                      >
                      </v-select>
                      <div class="md-error" v-if="errors[0]">
                        {{ $t(errors[0]) }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </ValidationObserver>
            <div style="margin-top: 15px">
              <md-button class="md-secondary mr-1" @click="hideAddModal">{{ $t("wms.close") }}</md-button>
              <md-button class="md-success" @click="addMovementOfGoods">{{ $t("wms.save") }}</md-button>
            </div>
          </template>
          <template slot="tab-pane-3">
            <ValidationObserver v-slot="{}" ref="manualAdjustmentForm">
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t("wms.historyType") }}</label>
                    <md-select v-model="form.historyType">
                      <md-option value="Removal For Spare Parts">Removal For Spare Parts</md-option>
                      <md-option value="Defect">Defect</md-option>
                      <md-option value="Manual Adjustment">Manual Adjustment</md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t("wms.reason") }}</label>
                    <md-input v-model="form.reason"> </md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout" style="align-items: baseline">
                <div class="md-layout-item md-size-50">
                  <ValidationProvider name="form.warehouseInventory" rules="required" v-slot="{ errors }">
                    <div class="wrap-v-select" style="text-align: left">
                      <v-select
                        :filterable="false"
                        :options="options"
                        v-model="form.warehouseInventory"
                        @search="onSearchInvetories"
                        :placeholder="$t('wms.inventory')"
                      >
                        <template slot="no-options"> no record... </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                      <div class="md-error" v-if="errors[0]">
                        {{ $t(errors[0]) }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-50">
                  <md-field>
                    <label>{{ $t("wms.quantity") }}</label>
                    <md-input v-model="form.quantity" type="number"> </md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-size-50">
                  <md-switch v-model="form.isAdd" class="md-primary">{{ form.isAdd ? "Add" : "Subtract" }}</md-switch>
                </div>
              </div>
            </ValidationObserver>
            <div style="margin-top: 15px">
              <md-button class="md-secondary mr-1" @click="hideAddModal">{{ $t("wms.close") }}</md-button>
              <md-button class="md-success" @click="addManualAdjustment">{{ $t("wms.save") }}</md-button>
            </div>
          </template>
        </tabs>
      </template>
      <template slot="footer"> </template>
    </modal>

    <modal v-if="historyModal" @close="hideHistoryModal" class-name="historical-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("wms.history") }}: {{ sku }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideHistoryModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <div style="height: calc(100vh - 150px); overflow-x: auto">
          <div style="display: flex; justify-content: start; margin-bottom: 20px">
            <div class="">
              <p class="date-title">{{ $t("wms.historyFromDateTime") }}</p>
              <date-time-picker
                :startDate="historyFilters.historyFromDateTime"
                :singleDate="true"
                @onChange="(d) => dateFilterChanged(d, 'historyFromDateTime')"
                class="datetime-input"
                timeFormat="HH:mm"
              />
            </div>
            <div style="margin-left: 20px">
              <p class="date-title">{{ $t("wms.historyToDateTime") }}</p>
              <date-time-picker
                :startDate="historyFilters.historyToDateTime"
                :singleDate="true"
                @onChange="(d) => dateFilterChanged(d, 'historyToDateTime')"
                class="datetime-input"
                timeFormat="HH:mm"
              />
            </div>
          </div>
          <div style="display: flex; justify-content: start; margin-bottom: 20px">
            <div style="min-width: 200px">
              <div class="wrap-v-select custom-wrap-v-select" style="text-align: left; margin-bottom: 0">
                <label>{{ $t("wms.location") }}</label>
                <v-select
                  :options="allLocationOptions"
                  :placeholder="$t('wms.location')"
                  @input="handleSelectHistoryLocation"
                  class="custom-select"
                >
                </v-select>
              </div>
            </div>
            <div style="margin-left: 20px">
              <md-field md-clearable>
                <label>{{ $t("wms.changeType") }}</label>
                <md-select
                  v-model="historyFilters.changeType"
                  name="filters.changeType"
                  id="filters.changeType"
                  @md-selected="debounceApplyFiltersHistory"
                >
                  <md-option key="All" value="">{{ $t("wms.all") }}</md-option>
                  <md-option key="Add" value="Add">{{ $t("wms.add") }}</md-option>
                  <md-option key="Subtract" value="Subtract">{{ $t("wms.subtract") }}</md-option>
                </md-select>
              </md-field>
            </div>
            <div style="margin-left: 20px">
              <md-field md-clearable>
                <label>{{ $t("wms.historyType") }}</label>
                <md-select
                  v-model="historyFilters.historyTypeId"
                  name="filter.historyTypeId"
                  id="filter.historyTypeId"
                  @md-selected="debounceApplyFiltersHistory"
                >
                  <md-option :key="i.id" v-for="i in historyTypeOptions" :value="i.id">{{
                    getI18N("wms.statuses.historyTypes", i.name)
                  }}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div style="display: flex; justify-content: start; margin-bottom: 20px">
            <div style="">
              <md-field md-clearable>
                <label>{{ $t("wms.reason") }}</label>
                <md-input v-model="historyFilters.reason" @input="debounceApplyFiltersHistory"></md-input>
              </md-field>
            </div>
            <div style="margin-left: 20px">
              <md-field md-clearable>
                <label>{{ $t("wms.changedBy") }}</label>
                <md-input v-model="historyFilters.changeBy" @input="debounceApplyFiltersHistory"></md-input>
              </md-field>
            </div>
          </div>
          <div>
            <v-server-table
              :columns="historyTableColumns"
              :options="historyTableOptions"
              ref="historyTable"
              class="table-warehouses-history"
              id="table-warehouses-history"
            >
              <template #warehouseName="{ row }">{{
                row.storageLocation && row.storageLocation.warehouseName
              }}</template>
              <template #dateTime="{ row }">
                {{ row.dateTime | formatDateTime }}
              </template>
              <template #location="{ row }">
                {{ row.storageLocation && row.storageLocation.fullName }}
              </template>
              <template #changedType="{ row }">
                <span v-if="row.changedType === 'Add'">
                  <span class="material-symbols-outlined">add</span>
                </span>
                <span v-else-if="row.changedType === 'Substract' || row.changedType === 'Subtract'">
                  <span class="material-symbols-outlined">remove</span>
                </span>
                <span v-else>
                  {{ row.changedType }}
                </span>
              </template>
              <template #changedBy="{ row }">
                {{ row.userWhoChangeName ? row.userWhoChangeName : "OCC" }}
              </template>
            </v-server-table>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import CSLoading from "../../components/Loading/CSLoading.vue";
import { Modal, Tabs } from "@/components";
import axiosFactory from "../../services/axios.factory";
import stickyTableHeader from "@/helpers/stickyTableHeader";
import { hasPermission, assignedWarehouse } from "../../mixins/auth";
import { normalizeStatusName } from "../../mixins/formats";
import { EventBus } from "@/helpers/event-bus";
export default {
  components: {
    CSLoading,
    Modal,
    Tabs,
    DateTimePicker,
  },
  data() {
    const userWarehouse = assignedWarehouse();
    return {
      isLoading: false,
      addModal: false,
      editModal: false,
      historyModal: false,
      form: {},
      filter: {
        keywords: null,
        warehouseId: userWarehouse ? userWarehouse.id : null,
      },
      sku: null,
      historyFilters: {
        storageLocationId: null,
        changeType: "",
        changeBy: "",
        reason: "",
        historyTypeId: null,
        location: "",
      },
      tableColumns: [
        "actions",
        "warehouse",
        "location",
        "sku",
        "name",
        "inventory",
        "reserved",
        "date",
        "purchasePrice",
        "additionalCosts",
        "exchangeRate",
        "valueOfGoods",
      ],
      tableOptions: {
        headings: {
          actions: () => this.$t("wms.table.actions"),
          warehouse: () => this.$t("wms.table.warehouse"),
          location: () => this.$t("wms.table.location"),
          sku: () => this.$t("wms.table.sku"),
          name: () => this.$t("wms.table.name"),
          inventory: () => this.$t("wms.table.inventory"),
          reserved: () => this.$t("wms.table.reserved"),
          date: () => this.$t("wms.table.date"),
          purchasePrice: () => this.$t("wms.table.purchasePrice"),
          additionalCosts: () => this.$t("wms.table.additionalCosts"),
          exchangeRate: () => this.$t("wms.table.exchangeRate"),
          valueOfGoods: () => this.$t("wms.table.valueOfGoods"),
        },
        perPage: 100,
        perPageValues: [100, 200, 500, 1000],
        filterable: false,
        sortIcon: {
          is: "fa-sort",
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
        },
        sortable: ["location", "date"],
        params: {},
        resizableColumns: false,
        requestFunction: this.requestFunction,
        responseAdapter: this.responseFunction,
        rowClassCallback: this.rowClassCallback,
        pagination: {
          show: true,
        },
        texts: {
          count: this.$t("table.count"),
          limit: this.$t("table.records"),
          noResults: this.$t("table.noRecord"),
        },
      },
      tableData: [],
      totalItems: 0,
      table: null,
      locationOptions: [],
      options: [],
      locations: [],
      products: [],

      historyTable: null,
      historyTableColumns: [
        "warehouseName",
        "dateTime",
        "location",
        "inStockOld",
        "inStockNew",
        "quantity",
        "totalOld",
        "totalNew",
        "changedType",
        "reason",
        "changedBy",
        "historyTypeName",
      ],
      historyTableOptions: {
        headings: {
          warehouseName: () => this.$t("wms.warehouseName"),
          dateTime: () => this.$t("wms.dateTime"),
          location: () => this.$t("wms.location"),
          inStockOld: () => this.$t("wms.inStockOld"),
          inStockNew: () => this.$t("wms.inStockNew"),
          quantity: () => this.$t("wms.quantity"),
          totalOld: () => this.$t("wms.totalOld"),
          totalNew: () => this.$t("wms.totalNew"),
          changedType: () => this.$t("wms.changedType"),
          reason: () => this.$t("wms.reason"),
          changedBy: () => this.$t("wms.changedBy"),
          historyTypeName: () => this.$t("wms.historyType"),
        },
        perPage: 16,
        perPageValues: [16],
        filterable: false,
        sortable: [],
        params: {},
        resizableColumns: false,
        requestFunction: this.historyRequestFunction,
        responseAdapter: this.historyResponseFunction,
        rowClassCallback: this.rowClassCallback,
        pagination: {
          show: true,
        },
        texts: {
          count: this.$t("table.count"),
          limit: this.$t("table.records"),
          noResults: this.$t("table.noRecord"),
        },
      },
    };
  },
  computed: {
    warehouseOptions() {
      return this.$store.state.warehouses.warehouses || [];
    },
    historyTypeOptions() {
      return this.$store.state.warehouses.historyTypes || [];
    },
    skuOptions() {
      return this.products.map((p) => p.sku) || [];
    },
    allLocationOptions() {
      return (
        this.locations.map((p) => ({
          value: p.id,
          label: p.fullName,
        })) || []
      );
    },
    isWarehouseAssigned() {
      return !!assignedWarehouse();
    },
  },
  methods: {
    showAddModal() {
      this.form = { id: 0 };
      this.form.date = new Date();
      this.addModal = true;
    },
    hideAddModal() {
      this.addModal = false;
    },
    showEditModal(item) {
      Object.assign(this.form, item);
      this.form.date = item.date ? new Date(item.date) : null;
      this.editModal = true;
    },
    hideEditModal() {
      this.editModal = false;
    },
    hideHistoryModal() {
      this.historyModal = false;
    },
    handleWarehouseChange() {
      this.isLoading = true;
      this.form.storageLocationId = null;
      this.$store
        .dispatch("warehouses/getLocationsByWarehouse", {
          warehouseId: this.form.warehouseId,
          location: "",
        })
        .then((res) => {
          this.locationOptions = res;
          this.isLoading = false;
        });
    },
    addWarehouse() {
      if (!this.form.storageLocationId || this.form.storageLocationId < 0) {
        this.$notify({
          timeout: 2500,
          message: "Please select storage location",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return;
      }

      this.isLoading = true;
      const inputs = Object.assign({}, this.form);
      inputs.purchasePrice = parseFloat(this.form.purchasePrice, 10);
      inputs.additionalCosts = parseFloat(this.form.additionalCosts, 10);
      inputs.reserved = parseInt(this.form.reserved, 10);

      if (!inputs.historyTypeId) {
        this.$notify({
          timeout: 2500,
          message: "The history type is required",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "error",
        });
        return;
      }

      axiosFactory()
        .post(`/warehouses/inventories?historyTypeId=${inputs.historyTypeId}`, inputs)
        .then((res) => {
          this.applyFilters({});
        })
        .then(() => {
          this.hideAddModal();
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    editWarehouse() {
      this.isLoading = true;
      const inputs = Object.assign({}, this.form);
      inputs.purchasePrice = parseFloat(this.form.purchasePrice, 10);
      inputs.additionalCosts = parseFloat(this.form.additionalCosts, 10);
      inputs.reserved = parseInt(this.form.reserved, 10);
      inputs.exchangeRate = parseFloat(this.form.exchangeRate, 10);
      axiosFactory()
        .put(`/warehouses/inventories/${inputs.id}?historyTypeId=${inputs.historyTypeId}`, inputs)
        .then((res) => {
          this.applyFilters({});
          this.$notify({
            timeout: 2500,
            message: "The warehouse inventory is updated",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .then(() => {
          this.hideEditModal();
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    debounceApplyFilters: debounce(function () {
      this.applyFilters({});
    }, 300),
    handleSelectLocation(value) {
      this.filter.storageLocationId = value?.value ?? undefined;

      this.debounceApplyFilters();
    },
    applyFilters(filters) {
      stickyTableHeader({ selector: "#table-warehouses" });
      this.$refs.table.options.params = {
        ...this.$refs.table.options.params,
        ...this.filter,
        ...filters,
        pageIndex: 0,
      };
      this.currentPage = 1;
      this.$refs.table.setPage(this.currentPage);
    },
    requestFunction(data) {
      const { page, limit, orderBy, ascending, ...filters } = data;
      this.currentPage = page;
      let params = {
        pageIndex: this.currentPage,
        pageSize: limit,
        orderBy,
        ascending,
        ...this.filter,
        ...filters,
      };

      this.isLoading = true;
      return axiosFactory()
        .get(`/warehouses/inventories`, { params })
        .catch(function (e) {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    responseFunction({ data }) {
      this.tableData = data.data.map((i) => ({ ...i, selected: false }));
      this.totalItems = data.total;

      return {
        data: this.tableData,
        count: data.total,
      };
    },
    rowClassCallback(row) {
      return "";
    },
    calcValueOfGoods(row) {
      return row.inventory * row.additionalCosts + row.purchasePrice * row.exchangeRate * row.inventory;
    },
    addIncomingGoods() {
      this.isLoading = true;
      const inputs = {};
      inputs.sku = this.form.sku;
      inputs.storageLocationId = this.form.storageLocation.value;
      inputs.inventory = this.form.inventory;
      inputs.reason = this.form.reason;
      inputs.purchasePrice = this.form.purchasePrice;
      inputs.additionalCosts = this.form.additionalCosts;
      inputs.exchangeRate = this.form.exchangeRate;

      axiosFactory()
        .post(`/warehouses`, inputs)
        .then((res) => {
          this.applyFilters({});
          this.$notify({
            timeout: 2500,
            message: "The warehouse inventory is added",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .then(() => {
          this.hideAddModal();
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    onSearchInvetories(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce((loading, search, vm) => {
      axiosFactory()
        .get(`/warehouses/inventories?keywords=${search}&pageIndex=0&pageSize=100`)
        .then((res) => {
          vm.options = res.data.data.map((p) => ({
            value: p.id,
            name: `${p.sku} (${p.warehouseCode}-${p.storageLocation}:${p.inventory})`,
          }));
          loading(false);
        });
    }, 300),
    addMovementOfGoods() {
      this.isLoading = true;
      const inputs = {};
      inputs.storageLocationId = this.form.toStorageLocation.value;
      inputs.quantity = this.form.quantity;
      inputs.warehouseInventoryId = this.form.warehouseInventory.value;

      axiosFactory()
        .post(`/warehouses/moving`, inputs)
        .then((res) => {
          this.applyFilters({});
          this.$notify({
            timeout: 2500,
            message: "The warehouse inventory is moved",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .then(() => {
          this.hideAddModal();
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    addManualAdjustment() {
      this.isLoading = true;
      const inputs = {};
      inputs.isAdd = this.form.isAdd;
      inputs.reason = this.form.reason;
      inputs.historyType = this.form.historyType;
      inputs.quantity = this.form.quantity;
      inputs.warehouseInventoryId = this.form.warehouseInventory.value;

      axiosFactory()
        .post(`/warehouses/manual_adjustment`, inputs)
        .then((res) => {
          this.applyFilters({});
          this.$notify({
            timeout: 2500,
            message: "The warehouse inventory is adjusted",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .then(() => {
          this.hideAddModal();
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    hasPermission(permissionName, tableName, columnName) {
      return hasPermission(permissionName, tableName, columnName);
    },
    showHistoryModal(sku) {
      this.sku = sku;
      this.currentPage = 1;
      this.historyModal = true;
    },
    dateFilterChanged(d, key) {
      this.historyFilters[key] = d.startDate;
      this.applyHistoryFilters({});
    },
    debounceApplyFiltersHistory: debounce(function () {
      this.applyHistoryFilters();
    }, 300),
    handleSelectHistoryLocation(value) {
      this.historyFilters.storageLocationId = value?.value ?? undefined;
      this.debounceApplyFiltersHistory();
    },
    applyHistoryFilters() {
      this.$refs.historyTable.options.params = {
        ...this.$refs.historyTable.options.params,
        ...this.historyFilters,
        pageIndex: 0,
      };
      this.currentPage = 1;
      this.$refs.historyTable.setPage(this.currentPage);
    },
    historyRequestFunction(data) {
      const { page, limit, orderBy, ascending, ...filters } = data;
      this.currentPage = page;
      let params = {
        pageIndex: this.currentPage,
        pageSize: limit,
        orderBy,
        ascending,
        ...filters,
      };

      this.isLoading = true;
      return axiosFactory()
        .get(`/warehouses/${this.sku}/histories`, { params })
        .catch(function (e) {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    historyResponseFunction({ data }) {
      console.log(data);
      return {
        data: data.data.items,
        count: data.data.totalItems,
      };
    },
    getI18N(prefix, statusName) {
      return this.$t(`${prefix}.${normalizeStatusName(statusName)}`);
    },
  },
  created() {
    this.$store
      .dispatch("warehouses/getLocationsByWarehouse", {
        warehouseId: 0,
        location: "",
        isPulldown: true,
      })
      .then((res) => {
        this.locations = res.filter(loc => loc.isActive);
      });
    axiosFactory()
      .get("shipping_data/products", {
        params: { keywords: "", pageSize: 10000 },
      })
      .then((res) => {
        this.products = res.data.data;
      });
    Promise.all([
      this.$store.dispatch("warehouses/getWarehouses"),
      this.$store.dispatch("warehouses/getHistoryTypes"),
    ]).then(() => {
      this.isLoading = false;
    });
  },
  updated() {
    EventBus.$emit("scroll:changed");
  },
};
</script>
<style scoped>
.details .md-table-cell {
  text-align: left;
}
.mr-1 {
  margin-right: 15px;
}
.date-title {
  margin-bottom: 0;
  text-align: left;
  font-size: 0.85em;
  color: #888;
}
</style>
