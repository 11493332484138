<template>
  <div class="md-layout">
    <CSLoading :is-loading="isLoading"></CSLoading>
    <div class="md-layout-item md-size-50 md-medium-size-100" v-if="hasPermission('Read', 'News', '')">
      <News />
    </div>
  </div>
</template>

<script>
import News from "@/components/Widgets/News.vue";
import CSLoading from "../../components/Loading/CSLoading.vue";
import { hasPermission } from "../../mixins/auth";

export default {
  components: {
    News,
    CSLoading,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    hasPermission(permissionName, tableName, columnName) {
      return hasPermission(permissionName, tableName, columnName);
    },
  }
};
</script>
