<template>
  <div class="md-layout">
    <CSLoading :is-loading="isLoading"></CSLoading>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>local_shipping</md-icon>
          </div>
          <h4 class="title">{{ $t("shippingPlan.shippingPlans") }}</h4>
          <md-button
            v-if="hasPermission('Write', 'ShippingPlans', '')"
            @click="
              shipmentRecordName = null;
              supplierOrderId = null;
              supplierName = null;
              containerArrivalDateInput = newDate();
              containerArrivalDateFixed = false;
              containerArrivalDatePlanned = false;
              containerArrivalDateProvisional = true;
              createModal = true;
            "
            class="md-icon-button md-success add-button"
          >
            <md-icon>add</md-icon>
          </md-button>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-25">
              <md-field md-clearable>
                <label>{{ $t("shippingPlan.keywords") }}</label>
                <md-input v-model="filter.keywords" @input="debounceApplyFilters"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-75 text-right" style="align-items: center">
              <h6 style="padding-right: 15px">
                {{ $t("shippingPlan.total") }}: {{ totalItems }} / {{ $t("shippingPlan.filtered") }}:
                {{ tableData.length }}
              </h6>
            </div>
          </div>

          <v-server-table
            :columns="tableColumns"
            :options="tableOptions"
            ref="table"
            class="table-shipping-plan"
            id="shipping-plan-table"
          >
            <template #h__selected>
              <div class="head-selected" style="width: 100px" v-if="hasPermission('Write', 'ShippingPlans', '')">
                <input type="checkbox" @click="handleCheckAll(!isCheckAll)" :checked="isCheckAll" />
                <br />
                <md-button class="md-success" @click="sendDocumentCI">
                  {{ $t("shippingPlan.sendCI") }}
                </md-button>
              </div>
            </template>
            <template #h__status>
              <div class="head-status" style="text-align: left">
                {{ $t("shippingPlan.table.status") | capitalized }} <br />
                {{ $t("shippingPlan.table.labels") | capitalized }}
              </div>
            </template>
            <template #h__supplier>
              <div class="head-supplier" style="text-align: left; width: 100px">
                {{ $t("shippingPlan.table.order") }}
                {{ $t("shippingPlan.table.id") | uppercase }}
                <br />
                {{ $t("shippingPlan.table.supplier") }}
              </div>
            </template>
            <template #h__shipment>
              <div class="head-shipment" style="width: 200px; text-align: left">
                {{ $t("shippingPlan.table.shipmentName") }}<br />
                {{ $t("shippingPlan.table.container") | capitalized }}#&nbsp;|&nbsp;{{
                  $t("shippingPlan.table.containerSize")
                }}
                <br />
                {{ $t("shippingPlan.table.carrier") }} | {{ $t("shippingPlan.table.shipment") | capitalized }}#
              </div>
            </template>
            <template #h__documents>
              <div class="head-documents" style="width: 100px">
                {{ $t("shippingPlan.table.documents") }}
              </div>
            </template>
            <template #h__arrival>
              <div class="head-arrival" style="width: 200px; text-align: left">
                {{ $t("shippingPlan.table.arrivalDestination") }} <br />
                {{ $t("shippingPlan.table.arrivalDateAndTime") }}
              </div>
            </template>
            <template #h__payment>
              <div class="head-payment" style="width: 100px; text-align: left">
                {{ $t("shippingPlan.table.totalAmount") }} <br />
                {{ $t("shippingPlan.table.prepayment") }} <br />
                {{ $t("shippingPlan.table.finalPayment") }}
              </div>
            </template>
            <template #h__freight>
              <div class="head-freight">
                {{ $t("shippingPlan.table.freight") }} <br />
                {{ $t("shippingPlan.table.customs") }}
              </div>
            </template>
            <template #h__accounting>
              <div class="head-accounting" style="width: 150px">
                {{ $t("shippingPlan.table.accounting") }}
              </div>
            </template>

            <template #selected="{ row }">
              <div class="text-center">
                <input
                  v-if="hasPermission('Write', 'ShippingPlans', '')"
                  type="checkbox"
                  :checked="row.selected"
                  @click="handleSelectRow(row)"
                />
                <div class="cell-actions text-center">
                  <span
                    v-if="hasPermission('LimitRead', 'ShippingPlans', 'History')"
                    @click="
                      currentShipmentRecordId = row.shipmentRecordId;
                      historicalModal = true;
                    "
                    ><md-icon>history</md-icon></span
                  >
                  <span v-if="hasPermission('Write', 'ShippingPlans', '')" @click="showEditModal(row)"
                    ><md-icon>edit</md-icon></span
                  >
                  <span
                    v-if="hasPermission('Write', 'ShippingPlans', '') && row.canRelease === true"
                    @click="release(row)"
                    ><md-icon>key_off</md-icon></span
                  >
                </div>
              </div>
            </template>
            <template #status="{ row }">
              <div class="cell-status">
                <md-chip
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'RecordStatus')"
                  :style="`background-color: ${row.recordStatusColor} !important`"
                  >{{ row.recordStatus }}</md-chip
                >
                <record-tag
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'RecordTags')"
                  :recordTag="row.recordTags"
                  :recordTags="recordTags"
                ></record-tag>
              </div>
            </template>
            <template #notes="{ row }">
              <div class="cell-notes">
                <span
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'ShipmentNote') && row.shipmentNote"
                  class="text-success material-symbols-outlined"
                  :title="row.shipmentNote"
                >
                  sticky_note_2
                </span>
                <span v-else class="text-gray material-symbols-outlined"> sticky_note_2 </span>
              </div>
            </template>
            <template #supplier="{ row }">
              <div class="cell-supplier">
                <span v-if="hasPermission('LimitRead', 'ShippingPlans', 'SupplierOrderId')">{{
                  row.supplierOrderId
                }}</span>
                <br />
                <strong v-if="hasPermission('LimitRead', 'ShippingPlans', 'SupplierName')">{{
                  row.supplierName
                }}</strong>
              </div>
            </template>
            <template #shipment="{ row }">
              <div class="cell-shipment">
                <strong v-if="hasPermission('LimitRead', 'ShippingPlans', 'ShipmentRecordName')">{{
                  row.shipmentRecordName
                }}</strong>
                <br />
                <span v-if="hasPermission('LimitRead', 'ShippingPlans', 'ContainerId') && row.containerId"
                  ><a :href="row.carrierShipmentUrl" target="_blank">{{ row.containerId }}</a>
                  <span @click="() => copyContainerId(row.containerId)" class="copy-containerid"
                    ><span class="text-gray material-symbols-outlined" style="font-size: 15px !important"
                      >content_copy</span
                    ></span
                  >
                </span>
                <span
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'ContainerSize') && row.containerSize"
                  class="text-gray"
                  >({{ row.containerSize }})</span
                >
                <br />
                <CarrierInfo
                  v-if="
                    hasPermission('LimitRead', 'ShippingPlans', 'CarrierName') &&
                    hasPermission('LimitRead', 'ShippingPlans', 'CarrierShipmentId')
                  "
                  :carrier-name="row.carrierName"
                  :carrier-name-id="row.carrierNameId"
                  :carrier-shipment-id="row.carrierShipmentId"
                ></CarrierInfo>
              </div>
            </template>

            <template #documents="{ row }">
              <div class="cell-documents">
                <span
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'ProformaInvoice') && row.proformaInvoice"
                  :title="row.proformaInvoice"
                  @click="
                    downloadDocument({
                      id: row.shipmentRecordId,
                      name: row.proformaInvoice,
                    })
                  "
                >
                  <span class="text-success material-symbols-outlined">receipt_long</span>
                  <span class="text-success cell-documents-text">PI</span>
                </span>
                <span v-else :title="row.proformaInvoice">
                  <span class="text-gray material-symbols-outlined">receipt_long</span>
                  <span class="text-gray cell-documents-text">PI</span>
                </span>
                <span
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'CommercialInvoice') && row.commercialInvoice"
                  :title="row.commercialInvoice"
                  @click="
                    downloadDocument({
                      id: row.shipmentRecordId,
                      name: row.commercialInvoice,
                    })
                  "
                >
                  <span class="text-success material-symbols-outlined">receipt_long</span>
                  <span class="text-success cell-documents-text">CI</span>
                </span>
                <span v-else :title="row.commercialInvoice">
                  <span class="text-gray material-symbols-outlined">receipt_long</span>
                  <span class="text-gray cell-documents-text">CI</span>
                </span>
                <span
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'PackingList') && row.packingList"
                  :title="row.packingList"
                  @click="
                    downloadDocument({
                      id: row.shipmentRecordId,
                      name: row.packingList,
                    })
                  "
                >
                  <span class="text-success material-symbols-outlined">receipt_long</span>
                  <span class="text-success cell-documents-text">PL</span>
                </span>
                <span v-else :title="row.packingList">
                  <span class="text-gray material-symbols-outlined">receipt_long</span>
                  <span class="text-gray cell-documents-text">PL</span>
                </span>
              </div>
            </template>
            <template #arrival="{ row }">
              <div class="cell-arrival">
                <destination-info
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'ContainerArrivalPlace')"
                  :placeId="row.containerArrivalPlaceId"
                  :placeName="row.containerArrivalPlace"
                ></destination-info>
                <br />
                <strong
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'ContainerArrivalDate') && row.containerArrivalDate"
                  >{{ row.containerArrivalDate | formatDayOfWeek }} {{ row.containerArrivalDate | formatDate }} |
                  {{ row.containerArrivalDate | formatTime }}</strong
                >
              </div>
            </template>
            <template #items="{ row }">
              <div class="cell-items">
                <span
                  class="material-symbols-outlined"
                  :class="{
                    'text-success': row.products && row.products.length > 0,
                    'text-gray': !row.products || row.products.length === 0,
                  }"
                >
                  package_2
                  <md-tooltip
                    v-if="
                      hasPermission('LimitRead', 'ShippingPlans', 'Products') && row.products && row.products.length > 0
                    "
                    md-direction="top"
                    ><span v-for="p in row.products" :key="p.id">{{ p.sku }} ({{ p.quantityDelivering }}) <br /></span>
                  </md-tooltip>
                </span>
              </div>
            </template>
            <template #china="{ row }">
              <div class="cell-china">
                <md-avatar :style="`border: 3px solid ${row.chinaStatusColor}`">
                  <img v-if="row.chinaUserAvatar" :src="buildAvatarLink(row.chinaUser)" alt="Avatar" />
                  <img v-else src="https://placehold.co/50" alt="Avatar" />
                  <md-tooltip v-if="row.chinaUserFullName"
                    ><span v-if="hasPermission('LimitRead', 'ShippingPlans', 'ChinaUser')">{{
                      row.chinaUserFullName
                    }}</span>
                    <br />
                    <span v-if="hasPermission('LimitRead', 'ShippingPlans', 'ChinaText')">{{ row.chinaText }}</span>
                    <br />
                    <span v-if="hasPermission('LimitRead', 'ShippingPlans', 'ChinaStatus')">{{
                      row.chinaStatus
                    }}</span></md-tooltip
                  >
                </md-avatar>
              </div>
            </template>
            <template #payment="{ row }">
              <div class="cell-payment">
                <span v-if="hasPermission('LimitRead', 'ShippingPlans', 'OrderTotalAmount')"
                  ><strong class="total-amount" v-if="row.orderTotalAmount">{{
                    row.orderTotalAmount | formatAmount
                  }}</strong></span
                >
                <span
                  class="prepayment"
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'PrepaymentAmount') && row.orderTotalAmount"
                  >{{ (row.prepaymentAmount || 0) | formatAmount }}</span
                >
                <span
                  class="full-payment"
                  v-if="
                    (hasPermission('LimitRead', 'ShippingPlans', 'OrderTotalAmount') &&
                      hasPermission('LimitRead', 'ShippingPlans', 'PrepaymentAmount') &&
                      row.prepaymentAmount) ||
                    row.orderTotalAmount
                  "
                  ><span
                    >{{ (row.orderTotalAmount - row.prepaymentAmount) | formatAmount }}
                    <span v-if="row.paymentStatus === 'Paid in Full'" class="text-success material-symbols-outlined"
                      >done</span
                    >
                    <span v-else class="text-danger material-symbols-outlined">close</span>
                  </span>
                </span>
              </div>
            </template>
            <template #freight="{ row }">
              <div class="cell-freight">
                <span
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'ShipmentPaidStatus')"
                  :title="`shipment paid status: ${row.shipmentPaidStatus}`"
                >
                  <span
                    :class="{
                      'text-success': row.shipmentPaidStatus === 'Paid',
                      'text-gray': row.shipmentPaidStatus !== 'Paid',
                    }"
                    class="material-symbols-outlined"
                    >request_quote</span
                  >
                </span>
                <span
                  v-if="hasPermission('LimitRead', 'ShippingPlans', 'CustomsPaidStatus')"
                  :title="`customs paid status: ${row.customsPaidStatus}`"
                >
                  <span
                    :class="{
                      'text-success': row.customsPaidStatus === 'Paid',
                      'text-gray': row.customsPaidStatus !== 'Paid',
                    }"
                    class="material-symbols-outlined"
                    >request_quote</span
                  >
                </span>
              </div>
            </template>
            <template #accounting="{ row }">
              <div class="cell-accounting" v-if="hasPermission('LimitRead', 'ShippingPlans', 'AccountingStatus')">
                <span v-if="row.accountingStatus === 'Transferred'" class="text-success material-symbols-outlined"
                  >done</span
                >
                <span v-else class="text-danger material-symbols-outlined">close</span>
                <strong v-if="hasPermission('LimitRead', 'ShippingPlans', 'AccountingStatusDate')">{{
                  row.accountingStatusDate | formatMonthYear | uppercase
                }}</strong>
              </div>
            </template>
            <template slot="appendBody">
              <tr class="tr-footer-shippping-plan">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ numberOfPlaces }} {{ $t("shippingPlan.table.positions") }}</td>
                <td></td>
                <td></td>
                <td class="cell-payment text-left">
                  <div class="cell-payment">
                    <span
                      ><strong class="total-amount">{{ (orderTotalAmount || 0) | formatAmount }}</strong></span
                    >
                    <span class="prepayment">{{ (prepaymentAmount || 0) | formatAmount }}</span>
                    <span class="full-payment" style="padding-right: 24px">{{
                      (orderTotalAmount - prepaymentAmount) | formatAmount
                    }}</span>
                  </div>
                </td>
                <td></td>
                <td></td>
              </tr>
            </template>
          </v-server-table>
        </md-card-content>
      </md-card>

      <modal v-if="editModal" @close="hideEditModal" class-name="shipping-plan-modal-container">
        <template slot="header">
          <h4 class="modal-title">{{ $t("shippingPlan.editShippingPlan") }}</h4>
          <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideEditModal">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <ValidationObserver v-slot="{}" ref="form">
            <tabs
              :tab-name="[
                $t('shippingPlan.shipment'),
                $t('shippingPlan.order'),
                $t('shippingPlan.china'),
                $t('shippingPlan.accounting'),
              ]"
              color-button="success"
              content-flex-direction-column
            >
              <template slot="tab-pane-1">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label for="recordStatus">{{ $t("shippingPlan.recordStatus") }}</label>
                      <md-select v-model="form.recordStatusId" name="recordStatus" id="recordStatus">
                        <md-option :key="i.id" v-for="i in recordStatusOptions" :value="i.id">{{
                          getI18N("shippingPlan.statuses.recordStatuses", i.name)
                        }}</md-option>
                      </md-select>
                    </md-field>
                    <md-field>
                      <vue-tags-input
                        class="record-tag-input"
                        :placeholder="$t('shippingPlan.addRecordTag')"
                        v-model="tag"
                        :tags="tags"
                        :autocomplete-items="filteredTags"
                        @tags-changed="(newTags) => (tags = newTags)"
                      />
                    </md-field>
                    <md-field>
                      <label>{{ $t("shippingPlan.containerId") }}</label>
                      <md-input v-model="form.containerId"></md-input>
                    </md-field>
                    <md-field>
                      <label for="containerSize">{{ $t("shippingPlan.containerSize") }}</label>
                      <md-select v-model="form.containerSizeId" name="containerSize" id="containerSize">
                        <md-option :key="i.id" v-for="i in containerSizeOptions" :value="i.id">{{
                          getI18N("shippingPlan.statuses.containerSizes", i.name)
                        }}</md-option>
                      </md-select>
                    </md-field>
                    <p class="date-title">
                      {{ $t("shippingPlan.containerArrivalDate") }}
                    </p>
                    <date-time-picker
                      :startDate="form.containerArrivalDateInput"
                      :singleDate="true"
                      @onChange="(d) => (form.containerArrivalDateInput = d.startDate)"
                      class="datetime-input"
                      timeFormat="HH:mm"
                    />
                    <md-checkbox v-model="form.containerArrivalDateFixed">{{
                      $t("shippingPlan.dateIsFixed")
                    }}</md-checkbox>
                    <md-checkbox v-model="form.containerArrivalDatePlanned">{{
                      $t("shippingPlan.planned")
                    }}</md-checkbox>
                    <md-checkbox v-model="form.containerArrivalDateProvisional">{{
                      $t("shippingPlan.provisional")
                    }}</md-checkbox>
                  </div>
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>{{ $t("shippingPlan.carrierShipmentId") }}</label>
                      <md-input v-model="form.carrierShipmentId"></md-input>
                    </md-field>
                    <md-field>
                      <label for="carrierName">{{ $t("shippingPlan.carrierName") }}</label>
                      <md-select v-model="form.carrierNameId" name="carrierName" id="carrierName">
                        <md-option :key="i.id" v-for="i in carrierNameOptions" :value="i.id">{{ i.name }}</md-option>
                      </md-select>
                    </md-field>
                    <md-field>
                      <label>{{ $t("shippingPlan.shipmentNote") }}</label>
                      <md-input v-model="form.shipmentNote"></md-input>
                    </md-field>
                    <ValidationProvider name="shipmentRecordName" rules="required" v-slot="{ errors }">
                      <md-field>
                        <label>{{ $t("shippingPlan.shipmentRecordName") }}</label>
                        <md-input v-model="form.shipmentRecordName"></md-input>
                        <div class="md-error" v-if="errors[0]">
                          {{ $t(errors[0]) }}
                        </div>
                      </md-field>
                    </ValidationProvider>
                    <md-field>
                      <label for="containerArrivalPlace">{{ $t("shippingPlan.containerArrivalPlace") }}</label>
                      <md-select
                        v-model="form.containerArrivalPlaceId"
                        name="containerArrivalPlace"
                        id="containerArrivalPlace"
                      >
                        <md-option :key="i.id" v-for="i in containerArrivalPlaceOptions" :value="i.id">{{
                          i.name
                        }}</md-option>
                      </md-select>
                    </md-field>
                    <md-field>
                      <label for="shipmentPaidStatus">{{ $t("shippingPlan.shipmentPaidStatus") }}</label>
                      <md-select v-model="form.shipmentPaidStatusId" name="shipmentPaidStatus" id="shipmentPaidStatus">
                        <md-option :key="i.id" v-for="i in shipmentPaidStatusOptions" :value="i.id">{{
                          getI18N("shippingPlan.statuses.shipmentPaidStatuses", i.name)
                        }}</md-option>
                      </md-select>
                    </md-field>
                    <md-field>
                      <label for="customsPaidStatus">{{ $t("shippingPlan.customsPaidStatus") }}</label>
                      <md-select v-model="form.customsPaidStatusId" name="customsPaidStatus" id="customsPaidStatus">
                        <md-option :key="i.id" v-for="i in customsPaidStatusOptions" :value="i.id">{{
                          getI18N("shippingPlan.statuses.customsPaidStatuses", i.name)
                        }}</md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </template>
              <template slot="tab-pane-2">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                    <ValidationProvider name="supplierOrderId" rules="required" v-slot="{ errors }">
                      <md-field>
                        <label>{{ $t("shippingPlan.supplierOrderId") }}</label>
                        <md-input v-model="form.supplierOrderId"></md-input>
                        <div class="md-error" v-if="errors[0]">
                          {{ $t(errors[0]) }}
                        </div>
                      </md-field>
                    </ValidationProvider>
                    <ValidationProvider name="supplierName" rules="required" v-slot="{ errors }">
                      <md-autocomplete v-model="supplierName" :md-options="supplierNamesOnly">
                        <label>{{ $t("shippingPlan.supplierName") }}</label>
                      </md-autocomplete>
                      <div class="md-error" v-if="errors[0]">
                        {{ $t(errors[0]) }}
                      </div>
                    </ValidationProvider>
                    <md-field style="min-height: 43px !important">
                      <div style="padding-bottom: 5px">
                        <md-chip
                          v-for="c in productTags"
                          class="md-success"
                          md-deletable
                          :key="c.text"
                          @md-delete="(e) => removeProduct(c)"
                          >{{ c.text }} ({{ c.count || 0 }})</md-chip
                        >
                      </div>
                    </md-field>
                    <div style="text-align: left; color: #4caf50 !important; cursor: pointer">
                      <span
                        @click="
                          addProductModal = true;
                          addProductTag = '';
                          addProductTags = [];
                          quantityDelivering = 0;
                        "
                        >{{ $t("shippingPlan.addProduct") }}</span
                      >
                    </div>
                    <date-picker
                      v-model="form.prepaymentDateInput"
                      format="YYYY-MM-DD"
                      type="date"
                      :placeholder="$t('shippingPlan.prepaymentDate')"
                      style="width: 100%; margin: 15px 0"
                    ></date-picker>
                    <date-picker
                      v-model="form.fullPaidDateInput"
                      format="YYYY-MM-DD"
                      type="date"
                      :placeholder="$t('shippingPlan.fullPaidDate')"
                      style="width: 100%"
                    ></date-picker>
                  </div>
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label for="paymentStatus">{{ $t("shippingPlan.paymentStatus") }}</label>
                      <md-select v-model="form.paymentStatusId" name="paymentStatus" id="paymentStatus">
                        <md-option :key="i.id" v-for="i in paymentStatusOptions" :value="i.id">{{
                          getI18N("shippingPlan.statuses.paymentStatuses", i.name)
                        }}</md-option>
                      </md-select>
                    </md-field>
                    <md-field>
                      <label>{{ $t("shippingPlan.orderTotalAmount") }}</label>
                      <md-input v-model="form.orderTotalAmount" type="number"></md-input>
                    </md-field>
                    <md-field>
                      <label for="prepaymentAmount">{{ $t("shippingPlan.prepaymentAmount") }}</label>
                      <md-input v-model="form.prepaymentAmount" type="number"></md-input>
                    </md-field>
                    <md-field>
                      <label>{{ $t("shippingPlan.proformaInvoice") }}</label>
                      <md-file
                        v-model="proformaInvoice"
                        @change="handleFileUpload('proformaInvoice', $event)"
                        accept=".pdf"
                      ></md-file>
                    </md-field>
                    <ul v-if="form.proformaInvoice">
                      <li style="text-align: left">
                        <span
                          >{{ form.proformaInvoice }}
                          <span @click="form.proformaInvoice = null"><md-icon>delete</md-icon></span></span
                        >
                      </li>
                    </ul>
                    <md-field>
                      <label>{{ $t("shippingPlan.commercialInvoice") }}</label>
                      <md-file
                        v-model="commercialInvoice"
                        @change="handleFileUpload('commercialInvoice', $event)"
                        accept=".pdf"
                      ></md-file>
                    </md-field>
                    <ul v-if="form.commercialInvoice">
                      <li style="text-align: left">
                        <span
                          >{{ form.commercialInvoice }}
                          <span @click="form.commercialInvoice = null"><md-icon>delete</md-icon></span></span
                        >
                      </li>
                    </ul>
                    <md-field>
                      <label>{{ $t("shippingPlan.packingList") }}</label>
                      <md-file
                        v-model="packingList"
                        @change="handleFileUpload('packingList', $event)"
                        accept=".pdf"
                      ></md-file>
                    </md-field>
                    <ul v-if="form.packingList">
                      <li style="text-align: left">
                        <span
                          >{{ form.packingList }}
                          <span @click="form.packingList = null"><md-icon>delete</md-icon></span></span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <template slot="tab-pane-3">
                <div class="md-layout">
                  <div class="md-layout-item md-size-small-50">
                    <md-field>
                      <label for="chinaStatus">{{ $t("shippingPlan.chinaStatus") }}</label>
                      <md-select v-model="form.chinaStatusId" name="chinaStatus" id="chinaStatus">
                        <md-option :key="i.id" v-for="i in chinaStatusOptions" :value="i.id">{{
                          getI18N("shippingPlan.statuses.chinaStatuses", i.name)
                        }}</md-option>
                      </md-select>
                    </md-field>
                    <md-field>
                      <label for="chinaText">{{ $t("shippingPlan.chinaText") }}</label>
                      <md-textarea v-model="form.chinaText" md-autogrow></md-textarea>
                    </md-field>
                    <md-field>
                      <label for="chinaUser">{{ $t("shippingPlan.chinaUser") }}</label>
                      <md-select v-model="form.chinaUser" name="chinaUser" id="chinaUser">
                        <md-option :key="i.id" v-for="i in chinaUserOptions" :value="i.id">{{ i.name }}</md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </template>
              <template slot="tab-pane-4">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label for="accountingStatusId">{{ $t("shippingPlan.accountingStatus") }}</label>
                      <md-select v-model="form.accountingStatusId" name="accountingStatusId" id="accountingStatusId">
                        <md-option :key="i.id" v-for="i in accountingStatusOptions" :value="i.id">{{
                          getI18N("shippingPlan.statuses.accountingStatuses", i.name)
                        }}</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50">
                    <date-picker
                      v-model="form.accountingStatusDateInput"
                      format="YYYY-MM-DD"
                      type="date"
                      :placeholder="$t('shippingPlan.accountingStatusDate')"
                      style="width: 100%; margin: 15px 0"
                    ></date-picker>
                  </div>
                </div>
              </template>
            </tabs>
          </ValidationObserver>
        </template>
        <template slot="footer">
          <md-button class="md-secondary mr-1" @click="hideEditModal">{{ $t("shippingPlan.close") }}</md-button>
          <md-button class="md-success" @click="editShipppingPlan">{{ $t("shippingPlan.save") }}</md-button>
        </template>
      </modal>

      <modal v-if="createModal" @close="hideCreateModal" class-name="shipping-plan-modal-container">
        <template slot="header">
          <h4 class="modal-title">
            {{ $t("shippingPlan.createShippingPlan") }}
          </h4>
          <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideCreateModal">
            <md-icon>clear</md-icon>
          </md-button>
        </template>
        <template slot="body">
          <ValidationObserver v-slot="{}" ref="createForm">
            <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <ValidationProvider name="shipmentRecordName" rules="required" v-slot="{ errors }">
                  <md-field>
                    <label>{{ $t("shippingPlan.shipmentRecordName") }}</label>
                    <md-input v-model="shipmentRecordName"></md-input>
                    <div class="md-error" v-if="errors[0]">
                      {{ $t(errors[0]) }}
                    </div>
                  </md-field>
                </ValidationProvider>
                <ValidationProvider name="supplierName" rules="required" v-slot="{ errors }">
                  <md-autocomplete v-model="supplierName" :md-options="supplierNamesOnly">
                    <label>{{ $t("shippingPlan.supplierName") }}</label>
                  </md-autocomplete>
                  <div class="md-error" v-if="errors[0]">
                    {{ $t(errors[0]) }}
                  </div>
                </ValidationProvider>
              </div>
              <div class="md-layout-item md-size-50">
                <ValidationProvider name="supplierOrderId" rules="required" v-slot="{ errors }">
                  <md-field>
                    <label>{{ $t("shippingPlan.supplierOrderId") }}</label>
                    <md-input v-model="supplierOrderId"></md-input>
                    <div class="md-error" v-if="errors[0]">
                      {{ $t(errors[0]) }}
                    </div>
                  </md-field>
                </ValidationProvider>
                <p class="date-title">
                  {{ $t("shippingPlan.containerArrivalDate") }}
                </p>
                <date-time-picker
                  :startDate="containerArrivalDateInput"
                  :singleDate="true"
                  @onChange="(d) => (containerArrivalDateInput = d.startDate)"
                  class="datetime-input"
                  timeFormat="HH:mm"
                />
              </div>
            </div>
          </ValidationObserver>
        </template>
        <template slot="footer">
          <md-button class="md-secondary mr-1" @click="hideCreateModal">{{ $t("shippingPlan.close") }}</md-button>
          <md-button class="md-success" @click="createShipppingPlan">{{ $t("shippingPlan.save") }}</md-button>
        </template>
      </modal>

      <modal v-if="addProductModal" @close="hideAddProductModal" class-name="shipping-plan-modal-container">
        <template slot="header">
          <h4 class="modal-title">{{ $t("shippingPlan.addProduct") }}</h4>
          <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideAddProductModal">
            <md-icon>clear</md-icon>
          </md-button>
        </template>
        <template slot="body">
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-autocomplete v-model="addProductTag" :md-options="linkedProductNameOptions">
                <label>{{ $t("shippingPlan.product") }}</label>
              </md-autocomplete>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field>
                <label for="quantityDelivering">{{ $t("shippingPlan.quantityDelivering") }}</label>
                <md-input v-model="quantityDelivering" type="number"></md-input>
              </md-field>
            </div>
          </div>
        </template>
        <template slot="footer">
          <md-button class="md-secondary mr-1" @click="hideAddProductModal">{{ $t("shippingPlan.close") }}</md-button>
          <md-button class="md-success" @click="addProductToShippingPlan">{{ $t("shippingPlan.save") }}</md-button>
        </template>
      </modal>

      <modal v-if="historicalModal" @close="hideHistoricalModal" class-name="historical-modal-container">
        <template slot="header">
          <h4 class="modal-title">{{ $t("shippingPlan.historical") }}</h4>
          <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideHistoricalModal">
            <md-icon>clear</md-icon>
          </md-button>
        </template>
        <template slot="body">
          <Historical2 :shipmentRecordId="currentShipmentRecordId"></Historical2>
        </template>
        <template slot="footer"> </template>
      </modal>
    </div>

    <drawer-filter @apply-filters="applyFilters"> </drawer-filter>
  </div>
</template>
<script>
import { forEach, debounce, sumBy, sum, remove, uniqBy } from "lodash";
import { format } from "date-fns";
import VueTagsInput from "@johmun/vue-tags-input";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import DatePicker from "vue2-datepicker";
import numeral from "numeral";

import { Tabs } from "@/components";
import { Modal } from "@/components";
import RecordTag from "./RecordTag.vue";
import DrawerFilter from "./DrawerFilter.vue";
import Historical2 from "./Historical2.vue";
import CarrierInfo from "./CarrierInfo.vue";
import DestinationInfo from "./DestinationInfo.vue";
import CSLoading from "../../components/Loading/CSLoading.vue";
import axiosFactory from "../../services/axios.factory";
import config from "../../config";
import { EventBus } from "@/helpers/event-bus";
import stickyTableHeader from "@/helpers/stickyTableHeader";
import { hasPermission } from "../../mixins/auth";
import { normalizeStatusName } from "../../mixins/formats";

export default {
  components: {
    Tabs,
    Modal,
    VueTagsInput,
    RecordTag,
    DrawerFilter,
    CSLoading,
    Historical2,
    CarrierInfo,
    DestinationInfo,
    DateTimePicker,
    DatePicker,
  },
  data() {
    return {
      isLoading: true,
      editModal: false,
      createModal: false,
      historicalModal: false,
      addProductModal: false,
      proformaInvoice: null,
      commercialInvoice: null,
      packingList: null,
      sdate: null,
      files: {
        proformaInvoice: null,
        commercialInvoice: null,
        packingList: null,
      },
      shipmentRecordName: null,
      supplierOrderId: null,
      supplierName: null,
      containerArrivalDateInput: new Date(),
      containerArrivalDateFixed: false,
      containerArrivalDatePlanned: false,
      containerArrivalDateProvisional: false,
      isFilter: false,
      tag: "",
      tags: [],
      productTag: "",
      productTags: [],
      addProductTag: "",
      addProductTags: [],
      quantityDelivering: 0,
      currentShipmentRecordId: null,
      form: {
        shipmentRecordId: null,
        recordStatusId: null,
        recordTags: null,
        shipmentNote: null,
        shipmentRecordName: null,
        linkedProductIds: null,
        supplierOrderId: null,
        supplierNameId: null,
        containerId: null,
        containerSizeId: null,
        containerArrivalDate: null,
        containerArrivalDateFixed: false,
        containerArrivalDatePlanned: false,
        containerArrivalDateProvisional: false,
        containerArrivalPlaceId: null,
        carrierShipmentId: null,
        carrierNameId: null,
        paymentStatusId: null,
        orderTotalAmount: null,
        prepaymentAmount: null,
        prepaymentDate: null,
        fullpaidDate: null,
        shipmentPaidStatusId: null,
        customsPaidStatusId: null,
        chinaStatusId: null,
        chinaText: null,
        chinaUser: null,
        accountingStatusId: null,
        accountingStatusDate: null,

        proformaInvoice: null,
        commercialInvoice: null,
        packingList: null,

        containerArrivalDateInput: null,
        prepaymentDateInput: null,
        fullPaidDateInput: null,
        accountingStatusDateInput: null,
      },
      filter: {
        keywords: null,
      },
      tableColumns: [
        "selected",
        "status",
        "notes",
        "supplier",
        "shipment",
        "documents",
        "arrival",
        "items",
        "china",
        "payment",
        "freight",
        "accounting",
      ],
      tableOptions: {
        headings: {
          status: () => this.$t("shippingPlan.table.status"),
          notes: () => this.$t("shippingPlan.table.notes"),
          supplier: () => this.$t("shippingPlan.table.supplier"),
          shipment: () => this.$t("shippingPlan.table.shipment"),
          documents: () => this.$t("shippingPlan.table.documents"),
          arrival: () => this.$t("shippingPlan.table.arrival"),
          items: () => this.$t("shippingPlan.table.items"),
          china: () => this.$t("shippingPlan.table.china"),
          payment: () => this.$t("shippingPlan.table.payment"),
          freight: () => this.$t("shippingPlan.table.freight"),
          accounting: () => this.$t("shippingPlan.table.accounting"),
        },
        perPage: 25,
        perPageValues: [25],
        filterable: false,
        sortable: [],
        params: {},
        resizableColumns: false,
        requestFunction: this.requestFunction,
        responseAdapter: this.responseFunction,
        rowClassCallback: this.rowClassCallback,
        columnsClasses: {
          notes: "td-shipping-plan td-notes",
          documents: "td-shipping-plan td-documents",
          items: "td-shipping-plan td-items",
          china: "td-shipping-plan td-china",
          payment: "td-shipping-plan td-payment",
          freight: "td-shipping-plan td-freight",
          accounting: "td-shipping-plan td-accounting",
        },
        pagination: {
          show: false,
        },
      },
      tableData: [],
      totalItems: 0,
      isCheckAll: false,
    };
  },
  computed: {
    recordStatusOptions() {
      return this.$store.state.shippingData.recordStatuses || [];
    },
    recordTagOptions() {
      return this.$store.state.shippingData.recordTags || [];
    },
    recordTags() {
      return this.$store.state.shippingData.recordTags || [];
    },
    supplierNameOptions() {
      return this.$store.state.shippingData.supplierNames || [];
    },
    supplierNamesOnly() {
      const sd = this.$store.state.shippingData;
      return (sd.supplierNames && sd.supplierNames.map((sn) => sn.name)) || [];
    },
    containerSizeOptions() {
      return this.$store.state.shippingData.containerSizes || [];
    },
    containerArrivalPlaceOptions() {
      return this.$store.state.shippingData.containerArrivalPlaces || [];
    },
    carrierNameOptions() {
      return this.$store.state.shippingData.carrierNames || [];
    },
    paymentStatusOptions() {
      return this.$store.state.shippingData.paymentStatuses || [];
    },
    shipmentPaidStatusOptions() {
      return this.$store.state.shippingData.shipmentPaidStatuses || [];
    },
    customsPaidStatusOptions() {
      return this.$store.state.shippingData.customsPaidStatuses || [];
    },
    chinaStatusOptions() {
      return this.$store.state.shippingData.chinaStatuses || [];
    },
    linkedProductOptions() {
      return this.$store.state.shippingData.linkedProducts || [];
    },
    linkedProductNameOptions() {
      const sd = this.$store.state.shippingData;
      return (sd.linkedProducts && sd.linkedProducts.map((sn) => sn.sku)) || [];
    },
    chinaUserOptions() {
      return this.$store.state.shippingData.chinaUsers || [];
    },
    accountingStatusOptions() {
      return this.$store.state.shippingData.accountingStatuses || [];
    },
    orderTotalAmount() {
      if (!this.tableData) return 0;
      return sumBy(this.tableData, (p) => {
        return p.orderTotalAmount;
      });
    },
    prepaymentAmount() {
      if (!this.tableData) return 0;
      return sumBy(this.tableData, (p) => {
        return p.prepaymentAmount;
      });
    },
    openAmount() {
      if (!this.$store.state.shippingPlans.shippingPlans) return 0;
      const arr = this.$store.state.shippingPlans.shippingPlans.map((p) => {
        return p.orderTotalAmount - p.prepaymentAmount;
      });
      return sum(arr);
    },
    filteredTags() {
      const recordTags = this.$store.state.shippingData.recordTags || [];
      const tags = recordTags.map((rt) => ({ text: rt.name }));
      return tags.filter((t) => {
        return t.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
    numberOfPlaces() {
      if (!this.tableData) return 0;

      return uniqBy(
        this.tableData.filter((t) => t.containerArrivalPlaceId),
        "containerArrivalPlaceId"
      ).length;
    },
  },
  methods: {
    hideEditModal() {
      this.release({ shipmentRecordId: this.form.shipmentRecordId });
      this.editModal = false;
    },
    hideCreateModal() {
      this.createModal = false;
    },
    hideAddProductModal() {
      this.addProductModal = false;
    },
    hideHistoricalModal() {
      this.historicalModal = false;
    },
    showEditModal(item) {
      this.isLoading = true;
      this.$store
        .dispatch("shippingPlans/block", {
          shipmentRecordId: item.shipmentRecordId,
        })
        .then((res) => {
          if (!res.isSuccess) {
            this.$notify({
              timeout: 2500,
              message: "The shipping plan is blocked",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            return;
          }

          item.canRelease = true;

          Object.assign(this.form, item);
          this.form.containerArrivalDateInput = item.containerArrivalDate
            ? new Date(item.containerArrivalDate)
            : this.newDate();
          this.form.prepaymentDateInput = item.prepaymentDate ? new Date(item.prepaymentDate) : null;
          this.form.fullPaidDateInput = item.fullpaidDate ? new Date(item.fullpaidDate) : null;
          this.form.accountingStatusDateInput = item.accountingStatusDate ? new Date(item.accountingStatusDate) : null;
          this.uploadFiles = [];
          this.tags = (item.recordTags && item.recordTags.split(",").map((t) => ({ text: t }))) || [];
          this.productTags =
            (item.products &&
              item.products.map((t) => ({
                text: t.sku,
                id: t.id,
                count: t.quantityDelivering,
              }))) ||
            [];
          let sn = this.supplierNameOptions.filter((s) => s.id === item.supplierNameId);
          this.supplierName = sn && sn[0] && sn[0].name;
          this.form.linkedProductIds = (item.products && item.products.map((p) => p.id)) || [];
          this.proformaInvoice = null;
          this.commercialInvoice = null;
          this.packingList = null;
          this.editModal = true;
        })
        .finally((_) => {
          this.isLoading = false;
        });
    },
    release(item) {
      this.isLoading = true;
      this.$store
        .dispatch("shippingPlans/release", {
          shipmentRecordId: item.shipmentRecordId,
        })
        .then((res) => {
          if (!res.isSuccess) {
            this.$notify({
              timeout: 2500,
              message: "You can not release this shipping plan",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "danger",
            });
            return;
          }

          item.canRelease = false;
          let releaseItem = this.tableData.find((i) => i.shipmentRecordId === item.shipmentRecordId);
          if (releaseItem) {
            releaseItem.canRelease = false;
          }

          this.$notify({
            timeout: 2500,
            message: "This shipping plan is released",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .finally((_) => {
          this.isLoading = false;
        });
    },
    createShipppingPlan() {
      const sn = this.supplierNameOptions.filter((s) => s.name === this.supplierName);
      const supplierNameId = sn && sn[0] && sn[0].id;
      this.$refs.createForm.validate().then((success) => {
        if (!success) {
          return;
        }

        this.isLoading = true;
        this.$store
          .dispatch("shippingPlans/createShippingPlan", {
            shipmentRecordName: this.shipmentRecordName,
            supplierOrderId: this.supplierOrderId,
            supplierNameId: supplierNameId,
            containerArrivalDate: this.containerArrivalDateInput.toISOString(),
            containerArrivalDateFixed: this.containerArrivalDateFixed ?? false,
            containerArrivalDatePlanned: this.containerArrivalDatePlanned ?? false,
            containerArrivalDateProvisional: this.containerArrivalDateProvisional ?? false,
          })
          .then(() => {
            this.$notify({
              timeout: 2500,
              message: "The shipping plan is created",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });

            this.applyFilters();
          })
          .then(() => {
            this.hideCreateModal();
          })
          .finally((_) => {
            this.isLoading = false;
          });
      });
    },
    handleFileUpload(key, event) {
      this.files[key] = event.target.files[0];
    },
    downloadDocument({ id, name }) {
      if (id === 0 || !name) return;

      const fileLink = document.createElement("a");

      fileLink.href = `${config.baseURLApi}/api/files/${id}?name=${name}`;
      fileLink.setAttribute("target", `_blank`);
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    addProductToShippingPlan() {
      const sku = this.addProductTag;
      const product = this.linkedProductOptions.find((p) => p.sku === sku);
      const productId = product ? product.id : 0;
      this.productTags.push({
        id: productId,
        text: sku,
        count: this.quantityDelivering,
      });
      this.addProductModal = false;
    },
    removeProduct(product) {
      if (this.productTags) {
        this.productTags = remove(this.productTags, (p) => p.text === product.text);
      }
    },
    editShipppingPlan() {
      const sn = this.supplierNameOptions.filter((s) => s.name === this.supplierName);
      if (!sn) {
        this.$notify({
          timeout: 2500,
          message: "The supplier name is invalid",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
        return;
      }

      this.form.supplierNameId = sn && sn[0] && sn[0].id;
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        const formData = new FormData();
        const inputs = Object.assign({}, this.form);
        inputs.containerArrivalDate = this.form.containerArrivalDateInput
          ? this.form.containerArrivalDateInput.toISOString()
          : null;
        inputs.prepaymentDate = this.form.prepaymentDateInput
          ? format(this.form.prepaymentDateInput, "yyyy-MM-dd")
          : null;
        inputs.fullpaidDate = this.form.fullPaidDateInput ? format(this.form.fullPaidDateInput, "yyyy-MM-dd") : null;
        inputs.accountingStatusDate = this.form.accountingStatusDateInput
          ? format(this.form.accountingStatusDateInput, "yyyy-MM-dd")
          : null;

        forEach(inputs, (val, key) => {
          if (val && key !== "linkedProductIds" && key !== "recordTags") {
            formData.append(`${key}`, val);
          }
        });

        if (inputs.documents) {
          formData.append(`existedDocumentIds`, inputs.documents.map((d) => d.id).join(","));
        }

        if (this.tags && this.tags.length > 0) {
          formData.append(`recordTags`, this.tags.map((t) => t.text).join(","));
        }

        if (this.productTags && this.productTags.length > 0) {
          formData.append(
            `linkedProducts`,
            JSON.stringify(
              this.productTags.map((t) => ({
                id: t.id || 0,
                name: t.text,
                quantityDelivering: t.count,
              }))
            )
          );
        } else {
          formData.append(`linkedProducts`, "");
        }

        if (this.files.proformaInvoice) {
          formData.append("proformaInvoiceFile", this.files.proformaInvoice);
        }

        if (this.files.commercialInvoice) {
          formData.append("commercialInvoiceFile", this.files.commercialInvoice);
        }

        if (this.files.packingList) {
          formData.append("packingListFile", this.files.packingList);
        }

        this.isLoading = true;
        axiosFactory()
          .put(`/shipping_plans`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.$notify({
              timeout: 2500,
              message: "The shipping plan is updated",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });

            this.applyFilters({});
          })
          .then(() => {
            this.hideEditModal();
          })
          .catch((err) => {
            //console.log(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    debounceApplyFilters: debounce(function () {
      this.applyFilters({});
    }, 300),
    applyFilters(filters) {
      stickyTableHeader({ selector: "#shipping-plan-table" });
      this.$refs.table.options.params = {
        ...this.$refs.table.options.params,
        ...this.filter,
        ...filters,
        pageIndex: 0,
      };
      this.currentPage = 1;
      this.$refs.table.setPage(this.currentPage);
    },
    newDate() {
      const d = new Date();
      return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 4, 0, 0);
    },
    requestFunction(data) {
      const { page, limit, orderBy, ascending, ...filters } = data;
      this.currentPage = page;
      let params = {
        pageIndex: this.currentPage,
        pageSize: limit,
        orderBy,
        ascending,
        ...filters,
      };

      this.isLoading = true;
      return axiosFactory()
        .get(`/shipping_plans`, { params })
        .catch(function (e) {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    responseFunction({ data }) {
      this.tableData = data.data.map((i) => ({ ...i, selected: false }));
      this.totalItems = data.total;

      return {
        data: this.tableData,
        count: data.total,
      };
    },
    rowClassCallback(row) {
      if (row.recordStatus === "Delivery date in planning") {
        return "delivery-date-in-planing";
      }

      if (row.recordStatus === "Delivery Date fixed") {
        return "delivery-date-fixed";
      }

      if (row.recordStatus === "Delivered") {
        return "delivered";
      }

      if (row.recordStatus === "Archived") {
        return "archived";
      }
      return "";
    },
    copyContainerId(val) {
      navigator.clipboard.writeText(val);
    },
    buildAvatarLink(userId) {
      return `${config.baseURLApi}/api/files/avatar/${userId}`;
    },
    handleCheckAll(check) {
      this.isCheckAll = check;
      for (let index = 0; index < this.tableData.length; index++) {
        this.tableData[index].selected = check;
      }
    },
    handleSelectRow(row) {
      row.selected = !row.selected;
    },
    sendDocumentCI() {
      const shipmentRecordIds = this.tableData.filter((t) => t.selected === true).map((t) => t.shipmentRecordId);
      if (shipmentRecordIds.length === 0) {
        this.$notify({
          timeout: 2500,
          message: "Please select the shipping plans",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "warning",
        });
        return;
      }
      this.isLoading = true;
      return axiosFactory()
        .put(`/shipping_plans/send_document_ci`, { shipmentRecordIds })
        .then(() => {
          this.$notify({
            timeout: 2500,
            message: "The documents is sent",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          return;
        })
        .catch(function (e) {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    hasPermission(permissionName, tableName, columnName) {
      return hasPermission(permissionName, tableName, columnName);
    },
    getI18N(prefix, statusName) {
      return this.$t(`${prefix}.${normalizeStatusName(statusName)}`);
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("shippingData/getRecordStatuses"),
      this.$store.dispatch("shippingData/getRecordTags"),
      this.$store.dispatch("shippingData/getSupplierNames"),
      this.$store.dispatch("shippingData/getContainerSizes"),
      this.$store.dispatch("shippingData/getContainerArrivalPlaces"),
      this.$store.dispatch("shippingData/getCarrierNames"),
      this.$store.dispatch("shippingData/getPaymentStatuses"),
      this.$store.dispatch("shippingData/getShipmentPaidStatuses"),
      this.$store.dispatch("shippingData/getCustomsPaidStatuses"),
      this.$store.dispatch("shippingData/getChinaStatuses"),
      this.$store.dispatch("shippingData/getLinkedProducts"),
      this.$store.dispatch("shippingData/getChinaUsers"),
      this.$store.dispatch("shippingData/getAccountingStatuses"),
    ]).then(() => {
      this.isLoading = false;
    });
  },
  mounted() {
    stickyTableHeader({ selector: "#shipping-plan-table" });
    EventBus.$on("language:changed", ({ locale }) => {
      numeral.locale(locale);
      this.applyFilters({});
    });
  },
  updated() {
    EventBus.$emit("scroll:changed");
  },
  beforeDestroy() {
    EventBus.$off("language:changed");
  },
};
</script>
<style>
.md-menu-content-bottom-start {
  z-index: 10001;
}
</style>
<style lang="css" src="./ShippingPlans.css"></style>
<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mr-1 {
  margin-right: 15px;
}

.mt-0 {
  margin-top: 0 !important;
}

.documents i {
  color: #47a44b !important;
}
.md-success {
  background-color: #47a44b !important;
}
.empty-product {
  background-color: gray;
}

.date-title {
  margin-bottom: 0;
  text-align: left;
  font-size: 0.85em;
  color: #888;
}

.tr-footer-shippping-plan td {
  background-color: darkgray;
}
</style>
