<template>
  <span><img v-if="logo" :src="logo" style="width: 18px; height: 18px" /> {{ placeName }}</span>
</template>
<script>
export default {
  name: "DestinationInfo",
  props: {
    placeName: {
      type: String,
    },
    placeId: {
      type: Number,
    },
  },
  computed: {
    containerArrivalPlaceOptions() {
      return this.$store.state.shippingData.containerArrivalPlaces || [];
    },
    logo() {
      const carrier = this.containerArrivalPlaceOptions.find((i) => i.id === this.placeId);
      return carrier && carrier.logo;
    },
  },
};
</script>
<style></style>
