import axiosFactory from "./axios.factory";
import TokenService from "./token.service";

class AuthService {
  async login({ email, password }) {
    const response = await axiosFactory().post("/auth", {
      email: email,
      password: password,
    });

    if (response.data.statusCode === 200) {
      TokenService.setToken(response.data.data);
      return response.data.data;
    }

    throw new Error("Invalid username or password");
  }

  async logout() {
    TokenService.clearToken();
    const response = await axiosFactory().put(`/auth/logout`, { logout: true });
    return response.data.data;
  }

  async register(user) {
    const response = await axiosFactory().post(`/auth/register`, user);
    if (response.data.statusCode === 200) {
      TokenService.setToken(response.data.data);
      return response.data.data;
    }

    throw new Error("Invalid username or password");
  }

  async forgotPassword({ email }) {
    const response = await axiosFactory().post(`/auth/forgot-password`, { email });
    return response.data.data;
  }

  async confirmForgotPassword({ email, password, otp }) {
    const response = await axiosFactory().put(`/auth/confirm-forgot-password`, { email, password, otp });
    return response.data.data;
  }

  async verifyEmail({ token }) {
    const response = await axiosFactory().put(`/auth/verify-email/${token}`);
    if (response.data.statusCode === 200) {
      TokenService.setToken(response.data.data);
      return response.data.data;
    }
    return response.data.data;
  }
}

export default new AuthService();
