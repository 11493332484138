<template>
  <md-toolbar
    md-elevation="0"
    class="md-transparent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $t("layout." + $route.name.toLowerCase()) }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-round md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <!-- <div class="md-autocomplete">
            <md-autocomplete
              class="search"
              v-model="selectedEmployee"
              :md-options="employees"
              :md-open-on-focus="false"
            >
              <label v-if="$route.meta.rtlActive">بحث...</label>
              <label v-else>Search...</label>
            </md-autocomplete>
          </div> -->
          <md-list>
            <md-list-item href="/app/dashboard">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">{{ $t("layout.dashboard") }}</p>
            </md-list-item>

            <li class="md-list-item">
              <a href="#" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
                      <p style="text-transform: none; font-size: 16px">{{ languageName }}</p>
                      <span class="material-symbols-outlined" style="margin-right: 0 !important">
                        arrow_drop_down
                      </span>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li><a @click.prevent="setLanguage('en')" href="#">English</a></li>
                      <li><a @click.prevent="setLanguage('de')" href="#">Deutsch</a></li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>

            <md-list-item href="javascript:void(0);" @click="logout" title="Logout">
              <i class="material-icons">logout</i>
              <p class="hidden-lg hidden-md">{{ $t("layout.layout") }}</p>
            </md-list-item>

            <!-- <md-list-item href="/app/user-profile">
              <i class="material-icons">person</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item> -->
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import numeral from "numeral";
import TokenService, { LANGUAGE_SELECTED } from "../../../services/token.service";
import { EventBus } from "@/helpers/event-bus";

export default {
  data() {
    return {
      user: TokenService.getUser(),
    };
  },
  computed: {
    languageName() {
      return this.$i18n.locale === "en" ? "English" : "Deutsch";
    },
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    logout() {
      this.$store.dispatch("auth/logoutUser").finally((_) => {
        this.$router.replace("/login");
      });
    },
    setLanguage(locale) {
      this.$i18n.locale = locale;
      TokenService.set(LANGUAGE_SELECTED, locale);
      EventBus.$emit("language:changed", { locale });
    },
  },
};
</script>

<style scoped>
.dropdown-menu li i.logout {
  float: left;
  margin-right: 5px;
}
.dropdown-menu li:hover i.logout {
  color: #fff;
}
.dropdown-menu li > strong {
  padding: 10px 33px;
  display: inline-block;
}
.dropdown-menu li > a {
  padding-left: 28px;
}
.dropdown-menu li > a.logout {
  padding: 8px 15px 8px 0;
}
.md-toolbar .dropdown-menu li > a:hover,
.md-toolbar .dropdown-menu li > a:focus {
  background-color: #47a44b !important;
}
</style>
