var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"postForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("news.headline")))]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.post.title),callback:function ($$v) {_vm.$set(_vm.post, "title", $$v)},expression:"post.title"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t("news.text")))]),_c('md-textarea',{attrs:{"md-autogrow":true,"rows":"10"},model:{value:(_vm.post.content),callback:function ($$v) {_vm.$set(_vm.post, "content", $$v)},expression:"post.content"}}),(errors[0])?_c('div',{staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()],1)]}}],null,true)}),_c('div',{staticClass:"text-right"},[_c('md-button',{staticClass:"md-secondary mr-1",on:{"click":_vm.handleCancel}},[_vm._v(_vm._s(_vm.$t("news.cancel")))]),_c('md-button',{staticClass:"md-danger mr-1",on:{"click":_vm.savePost}},[_vm._v(_vm._s(_vm.$t("news.save")))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }