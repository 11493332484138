<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <span class="material-symbols-outlined">home_storage</span>
          </div>
          <h4 class="title">{{ $t("wms.storageLocations") }}</h4>
          <md-button
            v-if="hasPermission('Write', 'StorageLocations', '')"
            class="md-icon-button md-success add-button"
            @click="
              warehouseId = null;
              level1 = null;
              level2 = null;
              level3 = null;
              createModal = true;
            "
          >
            <md-icon>add</md-icon>
          </md-button>
        </md-card-header>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-25">
              <div class="wrap-v-select custom-wrap-v-select" style="text-align: left; margin-bottom: 0">
                <label>{{ $t("wms.location") }}</label>
                <v-select
                  :options="allLocationOptions"
                  :placeholder="$t('wms.location')"
                  @input="handleSelectLocation"
                  class="custom-select"
                >
                </v-select>
              </div>
              </div>
              <div class="md-layout-item md-size-25">
              <md-field md-clearable>
                <label>{{ $t("wms.location") }}</label>
                <md-input v-model="filter.location" @input="debounceApplyFilters" ></md-input>
              </md-field>
            </div>
          </div>
          <md-table v-model="tableData" table-header-color="green" class="table-sticky">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
              <md-table-cell v-if="hasPermission('LimitRead', 'StorageLocations', 'Name')" :md-label="$t('wms.name')">{{
                displayName(item)
              }}</md-table-cell>
              <md-table-cell md-label="Priority">
                {{ item.priority }}
              </md-table-cell>
              <md-table-cell md-label="Status">
                <div :class="item.isActive ? 'status-active' : 'status-inactive'">
                  {{ item.isActive ? $t("storageLocation.active") : $t("storageLocation.inactive") }}
                </div>
              </md-table-cell>
              <md-table-cell :md-label="$t('wms.actions')">
                <div v-if="hasPermission('Write', 'StorageLocations', '')" class="table-actions">
                  <md-button
                    :disabled="!!currentLocation"
                    class="md-icon-button md-raised md-danger"
                    v-if="item.isActive"
                    @click="handleArchiveLocation(item)"
                    ><md-icon>archive</md-icon>
                    <md-tooltip md-direction="top">{{ $t("storageLocation.archive") }}</md-tooltip>
                  </md-button>
                  <md-button
                    v-else
                    :disabled="!!currentLocation"
                    class="md-icon-button md-raised md-success"
                    @click="handleUnarchiveLocation(item)"
                    ><md-icon>unarchive</md-icon>
                    <md-tooltip md-direction="top">{{ $t("storageLocation.unarchive") }}</md-tooltip>
                  </md-button>
                  <md-button
                    :disabled="!!currentLocation"
                    class="md-icon-button md-raised"
                    @click="handleEdit(item)"
                    ><md-icon>edit</md-icon>
                    <md-tooltip md-direction="top">{{ $t("storageLocation.edit") }}</md-tooltip>
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
    <modal v-if="createModal || editModal" @close="hideCreateModal" class-name="shipping-plan-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ locationId ? $t("wms.updateStorageLocation") : $t("wms.createStorageLocation") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideCreateModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <ValidationObserver v-slot="{}" ref="createForm">
          <md-field>
            <label>{{ $t("wms.warehouses") }}</label>
            <md-select v-model="warehouseId" name="warehouseId" id="warehouseId" :disabled="!!locationId">
              <md-option :key="i.id" v-for="i in warehouseOptions" :value="i.id">{{ i.name }}</md-option>
            </md-select>
          </md-field>
          <ValidationProvider name="level1" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("wms.level1") }}</label>
              <md-input v-model="level1" type="text"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="level2" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("wms.level2") }}</label>
              <md-input v-model="level2" type="text"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <ValidationProvider name="level3" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("wms.level3") }}</label>
              <md-input v-model="level3" type="text"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>

          <ValidationProvider name="priority" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("wms.priority") }}</label>
              <md-input v-model="priority" type="number" inputmode="decimal" :placeholder="$t('wms.priorityDesc')"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
          <md-checkbox v-model="isActive"> {{ $t('storageLocation.active')}} </md-checkbox>  
        </ValidationObserver>
      </template>

      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideCreateModal">{{ $t("wms.close") }}</md-button>
        <md-button class="md-success" @click="createStorageLocation">{{ $t("wms.save") }}</md-button>
      </template>
    </modal>
    <md-dialog :md-active.sync="deleteModal" :md-click-outside-to-close="false">
      <md-dialog-title> {{ confirmTitle }} </md-dialog-title>
      <md-dialog-content v-html="confirmMessage"> </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-secondary" @click="hideDeleteModal">{{ $t("storageLocation.cancel") }}</md-button>
        <md-button class="md-danger" @click="toggleLocation">{{ $t("storageLocation.OK") }}</md-button>
      </md-dialog-actions>
    </md-dialog>
    <modal v-if="inventoryModal" @close="hideInventoryModal" class-name="location-modal-container">
      <template slot="header">
        <h4 class="modal-title">
          {{ $t("storageLocation.cannotArchive") }} <b>{{ displayName(currentLocation) }}</b>
        </h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideInventoryModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <div class="noti md-body-1 text-danger">{{ $t("storageLocation.cannotActionArchive") }}</div>
        <md-table v-model="listInventories" table-header-color="green" class="table-sticky text-left">
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell
              v-if="hasPermission('LimitRead', 'Warehouses', 'Sku')"
              :md-label="$t('wms.table.sku')"
              class="text-left"
            >
              {{ item.sku }}
            </md-table-cell>
            <md-table-cell :md-label="$t('wms.table.inventory')" class="text-left">{{ item.inventory }}</md-table-cell>
            <md-table-cell :md-label="$t('wms.table.reserved')" class="text-left">{{ item.reserved }}</md-table-cell>
          </md-table-row>
        </md-table>
      </template>
      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideInventoryModal">{{ $t("storageLocation.close") }}</md-button>
        <!-- <md-button class="md-danger mr-1" @click="deleteLocation">{{ $t("storageLocation.OK") }}</md-button> -->
      </template>
    </modal>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { Modal } from "@/components";
import axiosFactory from "../../services/axios.factory";

import { hasPermission } from "../../mixins/auth";

import { EventBus } from "@/helpers/event-bus";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      createModal: false,
      editModal: false,
      level1: null,
      level2: null,
      level3: null,
      priority: null,
      isActive: true,
      warehouseId: null,
      locationId: null,
      locations: [],
      filter: {
        location: "",
        storageLocationId: null,
      },
      deleteModal: false,
      inventoryModal: false,
      currentLocation: null,
      listInventories: [],
    };
  },
  computed: {
    tableData() {
      return this.$store.state.warehouses.locationsByWarehouse || [];
    },
    allLocationOptions() {
      // const locations = this.$store.state.warehouses.locationsByWarehouse || [];
      return (
        this.locations.map((p) => ({
          value: p.id,
          label: p.fullName,
        })) ?? []
      );
    },
    warehouseOptions() {
      return this.$store.state.warehouses.warehouses || [];
    },
    confirmMessage() {
      if (!this.currentLocation) {
        return "";
      }
      return this.currentLocation?.isActive
        ? this.$t(`storageLocation.confirmArchiveMessage`, {
            nameStockLocation: this.displayName(this.currentLocation),
          })
        : this.$t(`storageLocation.confirmUnarchiveMessage`, {
            nameStockLocation: this.displayName(this.currentLocation),
          });
    },
    confirmTitle() {
      if (!this.currentLocation) {
        return "";
      }
      return this.currentLocation?.isActive
        ? this.$t("storageLocation.confirmArchive")
        : this.$t("storageLocation.confirmUnarchive");
    },
  },
  methods: {
    async handleArchiveLocation(item) {
      this.currentLocation = item;

      const inventories = await this.checkInventory({
        pageIndex: 0,
        pageSize: 100,
        storageLocationId: item.id,
      });

      if (inventories.length > 0) {
        this.listInventories = inventories;
        this.inventoryModal = true;
      } else {
        this.deleteModal = true;
      }
    },
    handleUnarchiveLocation(item) {
      this.currentLocation = item;
      this.deleteModal = true;
    },
    hideInventoryModal() {
      this.resetData();
    },
    hideDeleteModal() {
      this.resetData();
      this.$notify({
        timeout: 2500,
        message: this.$t("storageLocation.nothingHappens"),
        icon: this.currentLocation?.isActive ? "unarchive" : "archive",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "info",
      });
    },
    async toggleLocation() {
      this.isLoading = true;
      this.deleteModal = false;
      await axiosFactory()
        .patch(
          `/warehouses/storage_locations/${this.currentLocation.id}/update_status/${
            this.currentLocation.isActive ? 0 : 1
          }`
        )
        .then(() => {
          this.$notify({
            timeout: 2500,
            message: this.$t("storageLocation.changed"),
            icon: this.currentLocation?.isActive ? "archive" : "unarchive",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((err) => {
          this.$notify({
            timeout: 2500,
            message: this.currentLocation?.isActive
              ? this.$t("storageLocation.cannotArchiveItem", {
                  nameStockLocation: this.displayName(this.currentLocation),
                })
              : this.$t("storageLocation.cannotUnarchiveItem", {
                  nameStockLocation: this.displayName(this.currentLocation),
                }),
            icon: "archive",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.resetData();
          this.debounceApplyFilters();
        });
    },
    resetData() {
      this.deleteModal = false;
      this.inventoryModal = false;
      this.currentLocation = null;
      this.listInventories = [];
      this.level1 = null;
      this.level2 = null;
      this.level3 = null;
      this.priority = 0;
      this.warehouseId = null;
      this.locationId = null;
      this.currentLocation = null;
    },
    async checkInventory(params) {
      this.isLoading = true;
      const inventoriesByLocation = await axiosFactory()
        .get(`/warehouses/inventories`, { params })
        .then((res) => res.data.data.filter((_item) => _item.inventory + _item.reserved > 0))
        .catch(function (e) {})
        .finally(() => {
          this.isLoading = false;
        });

      return inventoriesByLocation;
    },
    handleSelectLocation(value) {
      this.filter.storageLocationId = value?.value ?? undefined;
      this.debounceApplyFilters();
    },
    hideCreateModal() {
      this.createModal = false;
      this.editModal = false;
      this.resetData();
    },
    handleEdit(item) {
      this.editModal = true;
      this.level1 = item.level1;
      this.level2 = item.level2;
      this.level3 = item.level3;
      this.priority = item.priority;
      this.isActive = item.isActive;
      this.warehouseId = item.warehouseId;
      this.locationId = item.id;
      this.currentLocation = item;
    },
    async createStorageLocation() {
      this.$refs.createForm.validate().then(async (success) => {
        if (!success) {
          return;
        }
        try {
          if(this.locationId){
            await this.$store
            .dispatch("warehouses/updateStorageLocation", {
              locationId: this.locationId,
              warehouseId: this.warehouseId,
              level1: this.level1,
              level2: this.level2,
              level3: this.level3,
              priority: this.priority,
              isActive: this.isActive,
            });
          } else {
            await this.$store
              .dispatch("warehouses/createStorageLocation", {
                warehouseId: this.warehouseId,
                level1: this.level1,
                level2: this.level2,
                level3: this.level3,
                priority: this.priority,
                isActive: this.isActive,
              })
          }
        } catch (err) {
          this.$notify({
            timeout: 2500,
            message: err?.response?.data?.message || this.$t("storageLocation.errorCannotSave"),
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
          });
          return;
        }

        const msg = this.locationId ? "The storage location is updated" : "The storage location is created";
        this.$notify({
          timeout: 2500,
          message: msg,
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success",
        });
        await this.$store.dispatch("warehouses/getLocationsByWarehouse", { warehouseId: 0, location: "" });    
        this.resetData();
        this.hideCreateModal();
      });
    },
    displayName(item) {
      if (!item) {
        return "";
      }
      return `${item.warehouseName} (${item.warehouseCode}-${item.level1}-${item.level2}-${item.level3})`;
    },
    debounceApplyFilters: debounce(function () {
      this.isLoading = true;
      this.$store.dispatch("warehouses/getLocationsByWarehouse", { warehouseId: 0, ...this.filter }).then(() => {
        this.isLoading = true;
      });
    }, 300),
    hasPermission(permissionName, tableName, columnName) {
      return hasPermission(permissionName, tableName, columnName);
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch("warehouses/getWarehouses"),
      this.$store.dispatch("warehouses/getLocationsByWarehouse", { warehouseId: 0, location: "" }),
    ]).then(([, _locations]) => {
      this.locations = _locations;
      this.isLoading = false;
    });
  },
  updated() {
    EventBus.$emit("scroll:changed");
  },
};
</script>
<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-1 {
  margin-right: 15px;
}

.md-card .md-card-header.md-card-header-icon .material-symbols-outlined {
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 33px;
  color: #fff;
  margin: 0 !important;
  font-size: 28px !important;
}
.md-table-cell-container {
  text-align: left;
}
.status-inactive {
  opacity: 0.4;
}
.md-checkbox {
  display: flex;
}
</style>
