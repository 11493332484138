<template>
  <div class="md-layout">
    <CSLoading :is-loading="isLoading"></CSLoading>
    <h2 class="page-title">Redirecting...</h2>
  </div>
</template>

<script>
import CSLoading from "../../components/Loading/CSLoading.vue";

export default {
  name: "Redirect",
  components: { CSLoading },
  data() {
    return {
      isLoading: true,
    };
  },
  created() {
    this.$router.push({ path: this.$route.query.to, query: { error: this.$route.query.error } });
  },
};
</script>
