<template>
  <ValidationObserver v-slot="{}" ref="postForm">
    <ValidationProvider name="title" rules="required" v-slot="{ passed, failed, errors }">
      <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
        <label>{{ $t("news.headline") }}</label>
        <md-input v-model="post.title" type="text"> </md-input>
        <div class="md-error" v-if="errors[0]">
          {{ $t(errors[0]) }}
        </div>
      </md-field>
    </ValidationProvider>
    <ValidationProvider name="content" rules="required" v-slot="{ passed, failed, errors }">
      <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
        <label>{{ $t("news.text") }}</label>
        <md-textarea v-model="post.content" :md-autogrow="true" rows="10"> </md-textarea>
        <div class="md-error" v-if="errors[0]">
          {{ $t(errors[0]) }}
        </div>
      </md-field>
    </ValidationProvider>
    <div class="text-right">
      <md-button class="md-secondary mr-1" @click="handleCancel">{{ $t("news.cancel") }}</md-button>
      <md-button class="md-danger mr-1" @click="savePost">{{ $t("news.save") }}</md-button>
    </div>
  </ValidationObserver>
</template> 

<script>
export default {
  props: {
    data: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      post: this.data ?? {
        title: "",
        content: "",
      },
      errors: {},
      isLoading: false,
    };
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    savePost() {
      if(this.post.id) {
        this.updatePost();
      } else {
        this.createPost();
      }
    },
    createPost() {
      this.$refs.postForm.validate().then((success) => {
        if (!success) {
          return;
        }

        const formData = {headline: this.post.title, text: this.post.content};
        this.isLoading = true;
        this.$store.dispatch("posts/create", formData)
        .then(() => {
          this.$emit("post-created");
        })
        .finally(() => {
          this.isLoading = false;
        });
      });
    },
    updatePost() {
      this.$refs.postForm.validate().then((success) => {
        if (!success) {
          return;
        }

        const formData = {id: this.post.id, headline: this.post.title, text: this.post.content};
        this.isLoading = true;
        this.$store.dispatch("posts/update",formData)
        .then(() => {
          this.$emit("post-updated");
        })
        .finally(() => {
          this.isLoading = false;
        });
      });
    },
  },
};
</script>