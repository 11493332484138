<template>
  <div :id="id" class="simple-datetime-picker">
    <date-time-picker
      :startDate="filter.date"
      :singleDate="true"
      @onChange="(d) => datetimeChanged(d)"
      class="datetime-input"
      timeFormat="HH:mm"
    />
    <button
      @click="clear"
      type="button"
      class="md-button md-icon-button md-dense md-input-action md-clear md-theme-default"
      tabindex="-1"
    >
      <div class="md-ripple">
        <div class="md-button-content">
          <i class="md-icon md-icon-font md-icon-image md-theme-default"
            ><svg
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              ></path>
              <path d="M0 0h24v24H0z" fill="none"></path></svg
          ></i>
        </div>
        <div></div>
      </div>
    </button>
  </div>
</template>
<script>
import $ from "jquery";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
export default {
  name: "SimpleDateTimePicker",
  components: { DateTimePicker },
  data() {
    return {
      filter: {},
    };
  },
  props: {
    id: { type: String, default: "" },
  },
  methods: {
    datetimeChanged(d) {
      this.$emit("datetimeChanged", d);
    },
    clear() {
      $(`#${this.id} input`).val("");
      this.$emit("clearDatetime");
    },
  },
};
</script>
<style scoped>
.simple-datetime-picker {
  position: relative;
}
.md-clear {
  position: absolute;
  top: 50%;
  right: 0px;
  border-radius: 15px;
  margin-top: -16px;
  background-color: #fff !important;
}
</style>
