<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <span class="material-symbols-outlined">precision_manufacturing</span>
          </div>
          <h4 class="title">{{ $t("suppliers.suppliers") }}</h4>
          <md-button v-if="hasPermission('Write', 'Suppliers', '')"
            class="md-icon-button md-success add-button"
            @click="
              name = null;
              createModal = true;
            "
          >
            <md-icon>add</md-icon>
          </md-button>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell v-if="hasPermission('Read', 'Suppliers', '')" md-label="ID">{{ item.id }}</md-table-cell>
              <md-table-cell v-if="hasPermission('ReadLimit', 'Suppliers', 'Name')" :md-label="$t('suppliers.name')">{{ item.name }}</md-table-cell>
              <md-table-cell :md-label="$t('suppliers.actions')">
                <span v-if="hasPermission('Write', 'Suppliers', '')" @click="showEditModal(item)"><md-icon>edit</md-icon></span></md-table-cell
              >
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
    <modal v-if="createModal" @close="hideCreateModal" class-name="shipping-plan-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("suppliers.createSupplier") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideCreateModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <ValidationObserver v-slot="{}" ref="createForm">
          <ValidationProvider name="name" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("suppliers.name") }}</label>
              <md-input v-model="name" type="text"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
        </ValidationObserver>
      </template>

      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideCreateModal">{{ $t("suppliers.close") }}</md-button>
        <md-button class="md-success" @click="createSupplier">{{ $t("suppliers.close") }}</md-button>
      </template>
    </modal>

    <modal v-if="editModal" @close="hideEditModal" class-name="shipping-plan-modal-container">
      <template slot="header">
        <h4 class="modal-title">{{ $t("suppliers.editSupplier") }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="hideEditModal">
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <ValidationObserver v-slot="{}" ref="editForm">
          <ValidationProvider name="name" rules="required" v-slot="{ passed, failed, errors }">
            <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
              <label>{{ $t("suppliers.name") }}</label>
              <md-input v-model="name" type="text"> </md-input>
              <div class="md-error" v-if="errors[0]">
                {{ $t(errors[0]) }}
              </div>
            </md-field>
          </ValidationProvider>
        </ValidationObserver>
      </template>

      <template slot="footer">
        <md-button class="md-secondary mr-1" @click="hideEditModal">{{ $t("suppliers.close") }}</md-button>
        <md-button class="md-success" @click="editSupplier">{{ $t("suppliers.save") }}</md-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { Modal } from "@/components";
import { EventBus } from "@/helpers/event-bus";
import { hasPermission } from "../../mixins/auth";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      createModal: false,
      editModal: false,
      name: null,
      id: null,
    };
  },
  computed: {
    tableData() {
      return this.$store.state.shippingData.supplierNames || [];
    },
  },
  methods: {
    hasPermission(permissionName, tableName, columnName) {
      return hasPermission(permissionName, tableName, columnName);
    },
    hideCreateModal() {
      this.createModal = false;
    },
    hideEditModal() {
      this.editModal = false;
    },
    showEditModal(sn) {
      this.name = sn.name;
      this.id = sn.id;
      this.editModal = true;
    },
    createSupplier() {
      this.$refs.createForm.validate().then((success) => {
        if (!success) {
          return;
        }
        this.$store
          .dispatch("shippingData/createSupplier", {
            name: this.name,
          })
          .then(() => {
            this.$notify({
              timeout: 2500,
              message: "The supplier is created",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });

            return this.$store.dispatch("shippingData/getSupplierNames");
          })
          .then(() => {
            this.hideCreateModal();
          });
      });
    },
    editSupplier() {
      this.$refs.editForm.validate().then((success) => {
        if (!success) {
          return;
        }

        this.$store
          .dispatch("shippingData/editSupplier", {
            id: this.id,
            name: this.name,
          })
          .then(() => {
            this.$notify({
              timeout: 2500,
              message: "The supplier is updated",
              icon: "add_alert",
              horizontalAlign: "right",
              verticalAlign: "top",
              type: "success",
            });

            return this.$store.dispatch("shippingData/getSupplierNames");
          })
          .then(() => {
            this.hideEditModal();
          });
      });
    },
  },
  created() {
    return this.$store.dispatch("shippingData/getSupplierNames");
  },
  updated() {
    EventBus.$emit("scroll:changed");
  },
};
</script>
<style scoped>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-1 {
  margin-right: 15px;
}

.md-card .md-card-header.md-card-header-icon .material-symbols-outlined {
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 33px;
  color: #fff;
  margin: 0 !important;
  font-size: 28px !important;
}
</style>
