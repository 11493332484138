<template>
  <span>
    <span v-if="logo" :title="carrierName"
      ><img :src="logo" style="width: 18px; height: 18px" /> {{ carrierShipmentId }}</span
    >
    <span v-else :title="carrierName"><md-icon>sailing</md-icon>{{ carrierShipmentId }}</span>
  </span>
</template>
<script>
export default {
  name: "CarrierInfo",
  props: {
    carrierShipmentId: {
      type: String,
    },
    carrierName: {
      type: String,
    },
    carrierNameId: {
      type: Number,
    },
  },
  computed: {
    carrierNameOptions() {
      return this.$store.state.shippingData.carrierNames || [];
    },
    logo() {
      const carrier = this.carrierNameOptions.find((i) => i.id === this.carrierNameId);
      return carrier && carrier.logo;
    },
  },
};
</script>
<style></style>
