import axiosFactory from "./axios.factory";

class UserService {
  async createUser(opts) {
    const result = await axiosFactory().post("/users", opts);
    return result.data.data;
  }

  async editUser(opts) {
    
    const result = await axiosFactory().put("/users", opts);
    return result.data.data;
  }

  async getUsers() {
    const result = await axiosFactory().get("/users");
    return result.data.data;
  }

  async getRoles() {
    const result = await axiosFactory().get("/users/roles");
    return result.data.data;
  }
}

export default new UserService();
