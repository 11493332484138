import axiosFactory from "./axios.factory";

class ShippingPlanService {
  async getShippingPlans(opts) {
    const result = await axiosFactory().get("/shipping_plans", { params: opts });
    return result.data.data;
  }

  async getShippingPlan({ shipmentRecordId }) {
    const result = await axiosFactory().get("/shipping_plans/" + shipmentRecordId);
    return result.data.data;
  }

  async getHistoricalShippingPlan({ shipmentRecordId }) {
    const result = await axiosFactory().get(`/shipping_plans/${shipmentRecordId}/histories`);
    return result.data.data;
  }

  async createShippingPlan(opts) {
    const result = await axiosFactory().post("/shipping_plans", opts);
    return result.data.data;
  }

  async block({ shipmentRecordId }) {
    const result = await axiosFactory().post(`/shipping_plans/${shipmentRecordId}/block`);
    return result.data.data;
  }

  async release({ shipmentRecordId }) {
    const result = await axiosFactory().post(`/shipping_plans/${shipmentRecordId}/release`);
    return result.data.data;
  }
}

export default new ShippingPlanService();
