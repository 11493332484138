<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <time-line plain :type="simpleTimeline">
        <time-line-item
          :inverted="item.inverted"
          badge-type="success"
          badge-icon="extension"
          :key="item.time"
          v-for="item in histories"
          :class="{ 'timeline-inverted': responsive }"
        >
          <badge slot="header" type="danger">{{ item.time | formatDateTime }}</badge>
          <div slot="content">
            <ul>
              <li>
                Modified by: <strong>{{ item.modifiedBy }}</strong>
              </li>
              <li :key="c.item1" v-for="c in item.valuesChanged.filter((i) => shouldNotDisplayProeprties(i.item1))">
                Changed <strong>{{ getPropertyText(c.item1) }}</strong> from <strong>{{ c.item3 || "N/A" }}</strong> to
                <strong> {{ c.item2 || "N/A" }}</strong>
              </li>
            </ul>
            {{ item.content }}
          </div>

          <!-- <h6 slot="footer">
            <i class="ti-time"></i>
            11 hours ago via Twitter
          </h6> -->
        </time-line-item>
      </time-line>
    </div>
  </div>
</template>
<script>
import { TimeLine, TimeLineItem, Badge } from "@/components";
export default {
  components: {
    TimeLine,
    TimeLineItem,
    Badge,
  },
  data() {
    return {
      responsive: false,
    };
  },
  computed: {
    simpleTimeline() {
      if (this.responsive) {
        return "simple";
      }
      return "";
    },
    histories() {
      return this.$store.state.shippingPlans.histories || [];
    },
  },
  methods: {
    onResponsiveInverted() {
      if (window.innerWidth < 768) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
    getPropertyText(propertyName) {
      return propertyName;
    },
    shouldNotDisplayProeprties(propertyName) {
      const excludes = [
        "Documents",
        "RecordStatusId",
        "RecordStatusColor",
        "RecordTagId",
        "RecordTagColor",
        "LinkedProductId",
        "ContainerSizeId",
        "ContainerArrivalPlaceId",
        "CarrierNameId",
        "PaymentStatusId",
        "ShipmentPaidStatusId",
        "CustomsPaidStatusId",
        "ChinaStatusId",
        "AuditEndTime",
        "AuditStartTime",
      ];
      return !excludes.includes(propertyName);
    },
  },
  created() {
    this.shipmentRecordId = this.$route.query.id;
    Promise.all([
      this.$store.dispatch("shippingPlans/getShippingPlan", { shipmentRecordId: this.shipmentRecordId }),
      this.$store.dispatch("shippingPlans/getHistoricalShippingPlan", { shipmentRecordId: this.shipmentRecordId }),
    ]).finally(() => {});
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },
};
</script>
<style></style>
